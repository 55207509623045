import axios from "axios";
import React, { useState } from "react";
import { bwcDealsApi } from "./Api";
import { IoArrowUndo } from "react-icons/io5";
import "./Comment.css"
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";



export const Comment = ({ elem}) => {

  const [show,setShow]= useState(false);
  const [comments,setComments] =useState("")
  const [errorMessage,setErrorMessage]= useState('')
  const [reply,setReply]= useState(true)
  const navigate= useNavigate();
  const token= JSON.parse(Cookies.get("loginTokenBwcDeals")===undefined?null:Cookies.get("loginTokenBwcDeals"));
  const csrftoken =  Cookies.get('csrftoken');
  
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();

    const diffInMilliseconds = now - date;
    const diffInSeconds = diffInMilliseconds / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    const diffInMonths = diffInDays / 30.44;
    const diffInYears = diffInDays / 365.25;

    if (diffInYears >= 1) {
      return Math.floor(diffInYears) + " years ago";
    } else if (diffInMonths >= 1) {
      return Math.floor(diffInMonths) + " months ago";
    } else if (diffInDays >= 1) {
      return Math.floor(diffInDays) + " days ago";
    } else if (diffInHours >= 1) {
      return Math.floor(diffInHours) + " hours ago";
    } else if (diffInMinutes >= 1) {
      return Math.floor(diffInMinutes) + " minutes ago";
    } else {
      return "Just now";
    }
  };

const handleNested = async(comment_id,comment_user_id,product_id,product_type) => {
    let collectedData = {
      user_id: comment_user_id,
      post_id: product_id,
      post_type: product_type,
      comment_id: comment_id,
      comment_text: comments,
    };
    if (comments.trim() !== '') {
      
      
    await  axios
      .post(`${bwcDealsApi}/product_add_comment`,collectedData,{
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      })
      .then((res) => {
        setComments('');
        setErrorMessage('');
        // setTimeout(()=>{
          window.location.reload()
        // },2000)
       
      })
      
      
    } else {
      setErrorMessage('Please enter a comment before posting.');
      // setTimeout(()=>{
      //   window.location.reload()
      // },3000)
    }

  
     
   
    
  };

  const handleImageClick =()=>{
    navigate(`/profile/${elem.user_id}`)
    // console.log(elem)
  }


  return (
    <>
    <div className="comment_main_div">
      <div>
        <img src={elem.user_image} alt="imag" className="user_image_individual" onClick={handleImageClick}/>
      </div>
      <div>
        <p>
          <span className="user_name"><b onClick={handleImageClick}>{elem.user_name}</b> </span> - {formatDate(elem.created_at)}
        </p>
      
        <div dangerouslySetInnerHTML={{ __html: elem.message }}  className="paragraph_description"/>
       
       {reply && <div onClick={() =>{setShow(true);setReply(false)}} className="reply_btn_div"> <IoArrowUndo style={{color:"#353F66", fontSize: "20px", marginBottom: "10px"}}/><span className="reply_btn">Reply</span></div>}
       {show && <div className="comment-section">
    


      <div className="new-comment">
        <textarea
          type="text"
          className="text_area_reply"
          onChange={(e) => {setComments(e.target.value);setErrorMessage("")}}
          placeholder="Add a comment..."
        />
         {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <button onClick={()=>handleNested(elem.comment_id,token && token.response.user_id,elem.product_id,elem.product_type)} className="comment_btn_comment">Comment</button>

        <button onClick={()=>{setShow(false); setReply(true)}} className="comment_btn_cancel">Cancel</button>
       
        </div>
      
    </div>}
      </div>

      </div>
      {elem.comments_replies && elem.comments_replies.length > 0 && (
        <div className="recursion_div">
          {elem.comments_replies.map((reply) => (
            <Comment key={reply.id} elem={{...reply,comment_user_id:token && token.response.user_id,product_id:elem.product_id,product_type:elem.product_type}} />
          ))}
        </div>
      )}
    </>
  );
};
