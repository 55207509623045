import { useNavigate } from "react-router-dom";
import "./Product.css";

export const Product = ({ item }) => {
  // const navigate = useNavigate();
  // const handleClick = () => {
  //   // navigate(`/post/${item.unique_id}/${item.slug}`)
  // };
  // const handleMouseClick = (event) => {
  //   if (event.button === 1) {
  //     window.open(`/post/${item.unique_id}/${item.slug}`, "_blank");
  //   }
  // };
  return (
    <>
      <a
        href={`/post/${item.unique_id}/${item.slug}`}
        alt="Product Details"
        className="product_a_tag"
      >
        <section className="card_box">
          <img src={item.image_url} alt={"Product"} className="card-image" />

          <div className="card-details">
            <div className="card-title">
              <p>{item.merchant_title || item.store_title}</p>
            </div>

            <section className="card-description">
              <span className="total-description">{item.title}</span>
            </section>
            <section className="card-price">
              <div className="price">
               <span className="price_hidden">Price: </span><span className="actual_price">$ {item.price}</span>
              </div>

              <div className="off_percent">{item.discount}% Off</div>
            </section>
          </div>
        </section>
      </a>
    </>
  );
};
