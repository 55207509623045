import React, { useEffect, useState } from "react";
import "./Product.css";
import { Alert, Breadcrumb } from "react-bootstrap";
import axios from "axios";
import { Pagination } from "./Pagination";
import "./SingleCategoryPage.css";
import { NavbarMain } from "../Components/Navbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Footer } from "./Footer";
import CategoryOffcanvas from "./CategoryOffcanvas";
import { CategoryProduct } from "./CategoryProduct";
import { bwcDealsApi } from "./Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { MetaTitleAndDescription } from "./MetaTitleAndDescription";

export const SingleCategoryPage = () => {
  document.title="- BwcDeals"
  const [api, setApi] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const [currentPage, setCurrentPage] = useState((page === null || page === undefined || page === "")?1:page);
  const itemsPerPage = 15;
  const [isAsideOpen, setIsAsideOpen] = useState(false);
  const [isCanvasOpen, setIsCanvasOpen] = useState(false);
  const [detail, setDetail] = useState("");
  const [searchTerm1, setSearchTerm1] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [title, setTitle] = useState([]);

  const [loading, setLoading] = useState(true);
  const [isData, setIsData] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [homeFilter, setHomeFilter] = useState(true);
  const navigate= useNavigate()
  const { slug } = useParams();
  const csrftoken =  Cookies.get('csrftoken');

  
  useEffect(() => {
    if (page === null || page === undefined || page === "") {
      setCurrentPage(1);
    } else {
      setCurrentPage(Number(page));
    }
  }, [page]);
  const firstData = () => {
    axios
      .post(`${bwcDealsApi}/category_page`, {
        slug: slug,
        type: "category",
        page: currentPage,
      },
      {
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      }
      )
      .then((r) => {
        if(r.data.status=="false"){
          setLoading(false)
          setIsData(true);
          setTitle({title:slug.charAt(0).toUpperCase() + slug.slice(1).replace(/-/g, " ")});
          // setApi([]);
          return;
        }
        setTitle(r.data.response.selected[0]);
        setApi(r.data.response.deal_data);
        if (r.data.response.deal_data.length === 0) {
          setFilterStatus(false);
          setApi([]);
          setIsData(true);
        } else {
          setFilterStatus(true);
          setIsData(false);
        }
        setDetail(r.data.response.deal_data_pagination.total);

        setLoading(false);
      })
      .catch((e) => {
        // console.log(e)
        setLoading(false);
      });
  };

  const searchFunction = (res) => {
  //  console.log("Response of selected search",res)
    axios
      .post(`${bwcDealsApi}/filter`, res, {
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      })
      .then((r) => {
        // console.log("searchFunction",r)
        if (r.data.status === "true") {
          setApi(r.data.response.deal_data);
          if (r.data.response.deal_data.length === 0) {
            setApi([]);
            setIsData(true);
            setLoading(false);
          } else {
            setIsData(false);
            setLoading(false);
          }
          
          setDetail(r.data.response.deal_data_pagination.total);
        } else {
          setApi([]);

          setIsData(true);
        }
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false); 
      });
  };

  let dataStore = selectedCategories.join(",");
  let dataGet = {
    store_id: dataStore,
    category_id: title.category_id,
    page: currentPage,
  };
  useEffect(() => {
    if (homeFilter) {
      setLoading(true);
      firstData();
    } else {
      setLoading(true);
      searchFunction(dataGet);
    }
  }, [currentPage]);

  const totalPages = Math.ceil(detail / itemsPerPage);

  const handleNextClick = () => {
    const nextPage = currentPage + 1;
    navigate(`?page=${nextPage}`);
    setFirstLoad(true);
  };

  const handlePrevClick = () => {
    const prevPage = currentPage - 1;
    if (prevPage > 0) {
      navigate(`?page=${prevPage}`);
    }
    setFirstLoad(true);
  };

  const handlePageClick = (pageNumber) => {
    navigate(`?page=${pageNumber}`);
    setFirstLoad(true);
  };

  const handleToggleCanvas = () => {
    setIsCanvasOpen(!isCanvasOpen);
  };

  const handleSearchChange1 = (e) => {
    setSearchTerm1(e.target.value);
  };

  const handleCategoryChangeLocal = (selectedCategoriess) => {
    navigate(`?page=${1}`);
    setSelectedCategories(selectedCategoriess);

    setCurrentPage(1);
    let data = selectedCategoriess.join(",");
    let discount_data = {
      store_id: data,
      category_id: title.category_id,
      page: 1,
    };
    setIsData(false);
    setLoading(true);
    if (selectedCategoriess.length === 0) {
      setHomeFilter(true);
      firstData();
    } else {
      setHomeFilter(false);
      searchFunction(discount_data);
    }
  };

  return (
    <>
     <MetaTitleAndDescription />
      <div className="container">
        <h2 style={{ marginTop: "20px" }}>{title && title.title} Deals</h2>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/category">Categories</Breadcrumb.Item>
          <Breadcrumb.Item active>{title && title.title}</Breadcrumb.Item>
        </Breadcrumb>
        {filterStatus && (
          <div className="row_reverse"
            // style={{
            //   width: "auto",
            //   display: "flex",
            //   flexDirection: "row-reverse",
            // }}
          >
            {<>
              <button
                onClick={handleToggleCanvas}
                className="toggle-canvas toggle-btn"
              >
                Filter →
              </button>
              <CategoryOffcanvas
              isCanvasOpen={isCanvasOpen}
              handleClose={() => setIsCanvasOpen(false)}
              handleSearchChange={handleSearchChange1}
              searchTerm={searchTerm1}
              selectedCategories={selectedCategories}
              handleCategoryChange={handleCategoryChangeLocal}
            /></>
            }
          </div>
        )}
        {loading ? (
          <div className="loading-spinner">
            <FontAwesomeIcon icon={faSpinner} spin fontSize="60px" />
          </div>
        ) : (<>
          <div className="store1">
            <div
              className={`product_grid1 ${isAsideOpen ? "grid1-4" : "grid1-4"}`}
            >
              {api &&
                api.length > 0 &&
                api.map((b) => <CategoryProduct item={b} key={b.store_id} />)}
            </div>

            
          </div>
        
        {isData && (
          <p className="single_page_product">
            <Alert variant="primary">No Data Found</Alert>
          </p>
        )}
        {api && api.length > 0 && totalPages > 1 && (
          <div className="page">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onNextClick={handleNextClick}
              onPrevClick={handlePrevClick}
              onPageClick={handlePageClick}
            />
          </div>
        )}
        </>
        )}
      </div>

      <Footer />
    </>
  );
};
