import { faFacebookSquare, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Register.css";
import axios from "axios";
import { Footer } from "./Footer";
import { bwcDealsApi } from "./Api";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "./firebase-config";
import { FacebookAuthProvider } from "firebase/auth";
import Cookies from "js-cookie";
import { MetaTitleAndDescription } from "./MetaTitleAndDescription";

export const Register = ({ onLogin }) => {
  document.title = "- BwcDeals";
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] =
    useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirm_password: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const csrftoken =  Cookies.get('csrftoken');
  const googleProvider = new GoogleAuthProvider();

  const registerWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      // console.log(result.user.providerData[0]);
      const registerData = {
        provider: "google",
        provider_id: result.user.providerData[0].uid,
        name: result.user.providerData[0].displayName,
        email: result.user.providerData[0].email,
        profile_img: result.user.providerData[0].photoURL,
      };
      // console.log("Form data",registerData)

      const response = await axios.post(
        `${bwcDealsApi}/social_signin`,
        registerData,
        {
          headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': csrftoken
          },
        }
      );
      //  console.log("REGISTER RESPONSE",response)

      Cookies.set("loginTokenBwcDeals", JSON.stringify(response.data.data));
      onLogin();
      setError(null);
      // setSuccess("Successfully Login");
      setSuccess("Registered Successfully !");

      setTimeout(() => {
        setSuccess(null);
        navigate("/");
      }, 3000);
    } catch (err) {
      setError("Something went wrong in social login. please try again later.");
      setSuccess(null);
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  const facebookProvider = new FacebookAuthProvider();

  const registerWithFacebook = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      // console.log(result.user.providerData[0]);
      const registerData = {
        provider: "facebook",
        provider_id: result.user.providerData[0].uid,
        name: result.user.providerData[0].displayName,
        email: result.user.providerData[0].email,
        profile_img: result.user.providerData[0].photoURL,
      };
      // console.log("Form data",registerData)

      const response = await axios.post(
        `${bwcDealsApi}/social_signin`,
        registerData,
        {
          headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': csrftoken
          },
        }
      );
      //  console.log("REGISTER RESPONSE",response)

      Cookies.set("loginTokenBwcDeals", JSON.stringify(response.data.data));
      onLogin();
      setError(null);
      // setSuccess("Successfully Login");
      setSuccess("Registered Successfully !");

      setTimeout(() => {
        setSuccess(null);
        navigate("/");
      }, 3000);
    } catch (err) {
      setError("Something went wrong in social login. please try again later.");
      setSuccess(null);
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  const openRegister = () => {
    navigate("/login");
  };

  const handleNameFocus = () => {
    setIsNameFocused(true);
  };

  const handleNameBlur = () => {
    setIsNameFocused(false);
  };
  const handleEmailFocus = () => {
    setIsEmailFocused(true);
  };

  const handleEmailBlur = () => {
    setIsEmailFocused(false);
  };
  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  const handlePasswordBlur = () => {
    setIsPasswordFocused(false);
  };

  const handleConfirmPasswordFocus = () => {
    setIsConfirmPasswordFocused(true);
  };

  const handleConfirmPasswordBlur = () => {
    setIsConfirmPasswordFocused(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });
    // setFormErrors({});
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));

    setIsSubmit(true);
    // console.log(validate(formValues));
    let registerData = {
      name: formValues.name,
      email: formValues.email,
      password: formValues.password,
    };

    const errors = validate(formValues);
    if (Object.keys(errors).length === 0) {
      try {
        const response = await axios.post(
          `${bwcDealsApi}/signup`,
          registerData,
          {
            headers: {
              "Content-Type": "application/json",
              'X-CSRFToken': csrftoken
            },
          }
        );
        // console.log("Response", response);
        if (response.status === 201) {
          setSuccess("Registered Successfully !");
          setError(null);

          setTimeout(() => {
            setSuccess(null);
            navigate("/verify");
          }, 3000);
        }
      } catch (err) {
        // console.log(err);
        setError(err.response.data.message.email);
        setSuccess(null);
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    } else {
      setFormErrors(errors);
    }
  };

  const validate = (values) => {
    const errors = {};
    const regexPattern =
      /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{4,32}$/;

    if (values.name === "") {
      errors.name = "Name is required !";
    } else if (values.name.length < 2) {
      errors.name = "Name should be more than 2 character!";
    }

    if (values.email === "") {
      errors.email = "Email is required !";
    }
    if (values.password === "") {
      errors.password = "Password is required !";
    } else if (!regexPattern.test(values.password)) {
      errors.password =
        "Password should at least one digit,one uppercase letter,one lowercase letter,one special character,and password between 4-32 characters in length.";
    }
    if (!values.confirm_password) {
      errors.confirm_password = "Confirm Password is required !";
    } else if (values.password !== values.confirm_password) {
      errors.confirm_password = "Password and Confirm password should be same.";
    }

    return errors;
  };

  return (
    <>
     <MetaTitleAndDescription />
      <div className="container-fluid">
        <div className="row justify-content-md-center">
          <div className="col-xxl-4 col-xl-6 col-md-8 col-lg-7 col-sm-11 loginAndRegisterForm">
            <div class="container mt-4">
              <div class="login-container">
                {success && (
                  <Alert variant="success" style={{ fontSize: "20px" }}>
                    {success}
                  </Alert>
                )}
                {/* ((error === "User with this email already exists.")|| (error==="Enter a valid email address.")) */}
                {error && (
                  <Alert variant="danger" style={{ fontSize: "20px" }}>
                    {error}
                  </Alert>
                )}
                <form onSubmit={handleSubmit}>
                  <Form>
                    <p className="register_text">Register</p>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <div className="mb-3">
                        <input
                          type="text"
                          placeholder="Name"
                          className="input_box"
                          onFocus={handleNameFocus}
                          onBlur={handleNameBlur}
                          style={{
                            border: isNameFocused
                              ? "1px solid rgba(53, 63, 102, 1)"
                              : "1px solid #ced4da",
                            boxShadow: isNameFocused ? "none" : "none",
                          }}
                          name="name"
                          value={formValues.name}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <p className="blank_message">{formErrors.name}</p>
                      </div>
                      <div className="mb-3">
                        <input
                          type="email"
                          className="input_box"
                          placeholder="Email"
                          onFocus={handleEmailFocus}
                          onBlur={handleEmailBlur}
                          style={{
                            border: isEmailFocused
                              ? "1px solid rgba(53, 63, 102, 1)"
                              : "1px solid #ced4da",
                            boxShadow: isEmailFocused ? "none" : "none",
                          }}
                          name="email"
                          value={formValues.email}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <p className="blank_message">{formErrors.email}</p>
                      </div>

                      <div className="mb-3">
                        <input
                          type="password"
                          placeholder="Password"
                          className="input_box"
                          onFocus={handlePasswordFocus}
                          onBlur={handlePasswordBlur}
                          style={{
                            border: isPasswordFocused
                              ? "1px solid rgba(53, 63, 102, 1)"
                              : "1px solid #ced4da",
                            boxShadow: isPasswordFocused ? "none" : "none",
                          }}
                          name="password"
                          value={formValues.password}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <p className="blank_message">{formErrors.password}</p>
                      </div>
                      <div className="mb-3">
                        <input
                          type="password"
                          placeholder="Confirm Password"
                          className="input_box"
                          onFocus={handleConfirmPasswordFocus}
                          onBlur={handleConfirmPasswordBlur}
                          style={{
                            border: isConfirmPasswordFocused
                              ? "1px solid rgba(53, 63, 102, 1)"
                              : "1px solid #ced4da",
                            boxShadow: isConfirmPasswordFocused
                              ? "none"
                              : "none",
                          }}
                          name="confirm_password"
                          value={formValues.confirm_password}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <p className="blank_message">
                          {formErrors.confirm_password}
                        </p>
                      </div>
                    </Form.Group>
                  </Form>

                  <button type="submit" className="login_button">
                    Register
                  </button>
                </form>
                <p className="or_para">Or</p>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className="form-group btn btn-primary fb-btn"
                        onClick={registerWithFacebook}
                      >
                        <FontAwesomeIcon
                          icon={faFacebookSquare}
                          className="svg_icon"
                        />
                        REGISTER WITH FACEBOOK
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div
                        className="form-group btn btn-primary gplus-btn"
                        onClick={registerWithGoogle}
                      >
                        <FontAwesomeIcon icon={faGoogle} className="svg_icon" />
                        REGISTER WITH GOOGLE
                      </div>
                    </div>
                  </div>
                </div>
                <p className="register_now">
                  Already have an account?{" "}
                  <span
                    type="button"
                    className="redirect-to-register-button link"
                    onClick={openRegister}
                  >
                    Login
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
