import React, { useEffect, useState } from "react";
import "./Category.css";
import { Alert, Breadcrumb } from "react-bootstrap";
import axios from "axios";
import { Pagination } from "../Components/Pagination";
import { FeaturedStoreCarousel } from "../Components/FeaturedStoreCarousel";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Footer } from "../Components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { bwcDealsApi } from "../Components/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { MetaTitleAndDescription } from "../Components/MetaTitleAndDescription";

export const Categories = () => {
  const [api, setApi] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const [currentPage, setCurrentPage] = useState((page === null || page === undefined || page === "")?1:Number(page));
  const navigate = useNavigate();
  const [category, setCategory] = useState(false);
  const [pagination, setPagination] = useState(0);
  const [loading,setLoading]=useState(true)

  const itemsPerPage = 20;

  const csrftoken =  Cookies.get('csrftoken');

  document.title="- BwcDeals"

  useEffect(() => {
    if (page === null || page === undefined || page === "") {
      setCurrentPage(1);
    } else {
      setCurrentPage(Number(page));
    }
  }, [page]);
  useEffect(() => {
    setLoading(true)
    axios
      .post(`${bwcDealsApi}/category_list`, {
        page: currentPage,
      },
      {
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      }
      )
      .then((r) => {
        
        setLoading(false)
        if(r.data.status == "false"){
          // console.log("kdhfkh")
         
           setCategory(true);
        }
        if(r.data.response && r.data.response.length===0)
        {
             setCategory(true);
        }
        setApi(r.data.response.selected_data);
        setPagination(r.data.response.deal_data_pagination.total);
        
      })
      .catch((e) => {
        setLoading(false)
      });
  }, [currentPage]);

  const totalPages = Math.ceil(pagination / itemsPerPage);

  const handleNextClick = () => {
    const nextPage = currentPage + 1;
    navigate(`?page=${nextPage}`);
  };

  const handlePrevClick = () => {
    const prevPage = currentPage - 1;
    if (prevPage > 0) {
      navigate(`?page=${prevPage}`);
    }
  };

  const handlePageClick = (pageNumber) => {
    navigate(`?page=${pageNumber}`);
  };

  const handleSingle = (b) => {
    navigate(`/category/${b.slug}`);
  };
  return (
    <>
    <MetaTitleAndDescription />
      <div className="container">
        <div className="educator_div_section">
          <div>
            <h2 style={{ marginTop: "20px" }}>Categories</h2>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Categories</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="educator_and_search_section"></div>
        </div>
        {loading?<div className="loading-spinner">
        <FontAwesomeIcon icon={faSpinner} spin fontSize="60px" />
      </div> :(api && api.length > 0 ? (
          <div className="store">
            <div className="productgrid">
              <ResponsiveMasonry
                columnsCountBreakPoints={{0:2, 767:3, 991: 4}}
              >
                <Masonry gutter="10px">
                  {api &&
                    api.map((b,index) => (
                      <section
                        className="card_box box"
                        onClick={() => handleSingle(b)}
                        key={index}
                      >
                        <div className="img_category">
                          <img
                            src={b.image_url}
                            alt={b.title}
                            className="card-image"
                          />
                        </div>
                        <div className="card-details">
                          <div className="card-title">
                            <p>{b.title}</p>
                          </div>
                        </div>
                      </section>
                    ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
            <div>
              <FeaturedStoreCarousel />
            </div>
          </div>
        ) : (
          category && <p className="store_no_data_found"><Alert  variant="primary">
          No Data Found 
      </Alert></p>
        ))}
        {(totalPages >1 && !loading) && (
          <div className="page">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onNextClick={handleNextClick}
              onPrevClick={handlePrevClick}
              onPageClick={handlePageClick}
            />
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};
