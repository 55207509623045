import { useNavigate } from "react-router-dom";
import "./CategoryProduct.css"
export const CategoryProduct = ({item}) => {
 
  // const navigate= useNavigate();
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    window.open(item.grab_deal_link, "_blank");
  };

  // const imageClick = () => {
  
  //   navigate(`/post/${item.unique_id}/${item.slug}`)
  // };

  return (
    <>
     <a
        href={`/post/${item.unique_id}/${item.slug}`}
        alt="Product Details"
        className="product_a_tag"
      >
      <section className="card_box_category" >
        <div className="product_img" >
         <img src={item.image_url} alt={"Category Product"} className="card-image-category" />
        </div>

        <div className="card-details">
            <div className="card-title"><p>{item.store_title}</p></div>
          
          <section className="card-description">
          
            <span className="total-description">{item.title}</span>
          </section>
          <section className="card-price_category" onClick={handleClick}>
            <div className="price">
             
                <span className="grab_deal">GRAB DEAL</span> 
            </div>

            <div className="off_percent">{item.discount}% Off</div>
          </section>
          
        </div>
      </section>

      </a>
    </>
  );
};