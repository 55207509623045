import React, { useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Button } from "react-bootstrap";

export const FilterPrice = ({ onFilter,priceRangeChanged,filteredPriceRangeFirstTime }) => {
  const [priceRange, setPriceRange] = useState([priceRangeChanged[0],priceRangeChanged[1]]);
 
  const handlePriceRangeChange = (values) => {
    setPriceRange(values);
  };

  const handleFilterClick = () => {
    onFilter(priceRange);
  };

  const handleTooltip = (value) => {
    return `$${value.toFixed(2)}`;
  };

  return (
    <div style={{ margin: "20px" }}>
      <label>Filter By: Price</label>
      <Slider
        range
        min={+filteredPriceRangeFirstTime[0]}
        max={+filteredPriceRangeFirstTime[1]}
        step={1}
        value={priceRange}
        onChange={handlePriceRangeChange}
        tipFormatter={handleTooltip}
        allowCross={false}
      />
      <div style={{ display: "flex", justifyContent: "space-between", margin: "10px" }}>
        <span>${priceRange[0]==""?0:priceRange[0]}</span>
        <span>${priceRange[1]==""?0:priceRange[1]}</span>
      </div>
      <Button variant="primary" onClick={handleFilterClick}>
        Apply Filter
      </Button>
    </div>
  );
};