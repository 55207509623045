import React, { useState } from 'react'
import { useEffect } from 'react'
import { Footer } from './Footer'
import { Breadcrumb } from 'react-bootstrap'
import { bwcDealsApi } from './Api'
import axios from 'axios'
import Cookies from 'js-cookie'

export const PrivacyPolicy = () => {


  document.title="- BwcDeals"
const [data,setData]= useState("")
const csrftoken =  Cookies.get('csrftoken');

    useEffect(()=>{
        axios
        .post(
          `${bwcDealsApi}/cms_page`,
          {
            slug : "privacy-policy"
          },
          {
            headers: {
              "Content-Type": "application/json",
              'X-CSRFToken': csrftoken
            },
          }
        )
  
        .then((res) => {
          setData(res.data.cms_page)
          
  
        })
        .catch((error) => {
        });
    },[])
  return (
    <>
      <div className="container">
        
        <div className="educator_div_section">
        <div>
        <h2 style={{ marginTop: "20px" }}>Privacy Policy</h2>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Privacy Policy</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="educator_and_search_section">
      </div>
      </div>
      {data && <div dangerouslySetInnerHTML={{ __html: data[0].detail }}  className="paragraph_description"/>}


      </div>

      <Footer />
    </>
  )
}
