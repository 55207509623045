import React from "react";
import "./Educators.css";
import { Badge, Breadcrumb, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Footer } from "../Components/Footer";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Pagination } from "../Components/Pagination";
import { bwcDealsApi } from "../Components/Api";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { MetaTitleAndDescription } from "../Components/MetaTitleAndDescription";

export const Educators = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const [forum, setForum] = useState([]);
  const [detail, setDetail] = useState("");
  const [educator,setEducator]= useState(false)
  const [loading,setLoading]= useState(true)
  const navigate = useNavigate();
  const token= JSON.parse(Cookies.get("loginTokenBwcDeals")===undefined?null:Cookies.get("loginTokenBwcDeals"));
  const csrftoken =  Cookies.get('csrftoken');

  document.title="- BwcDeals";

  useEffect(() => {
    setLoading(true)
    axios
      .post(`${bwcDealsApi}/category_page`, {
        slug:"educators",
        type:"g",
        page:currentPage
      },
      {
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      }
      )
      .then((r) => {
        setLoading(false)
        // console.log("Educator page",r)
        setForum(r.data.response.deal_data);
        if(r.data.response.deal_data && r.data.response.deal_data===0){
          setEducator(true)
        }
        setDetail(r.data.response.deal_data_pagination.total);
      })
      .catch((e) => {
        setLoading(false)
        // console.log(e)
      });
  }, [currentPage]);

  

  const handleDetail = (id, slug) => {
    navigate(`/educator/${id}/${slug}`);
  };


  const totalPages = Math.ceil(detail / itemsPerPage);

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
  
    const diffInMilliseconds = now - date;
    const diffInSeconds = diffInMilliseconds / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    const diffInMonths = diffInDays / 30.44; 
    const diffInYears = diffInDays / 365.25;
  
    if (diffInYears >= 1) {
      return Math.floor(diffInYears) + " years ago";
    } else if (diffInMonths >= 1) {
      return Math.floor(diffInMonths) + " months ago";
    } else if (diffInDays >= 1) {
      return Math.floor(diffInDays) + " days ago";
    } else if (diffInHours >= 1) {
      return Math.floor(diffInHours) + " hours ago";
    } else if (diffInMinutes >= 1) {
      return Math.floor(diffInMinutes) + " minutes ago";
    } else {
      return "Just now";
    }
  };


const handlePostNewTopic =()=>{
  if(token==="null"){
    navigate("/login")
  }
  else{
    navigate("/submitdeal")
  }
}
  return (
    <>
    <MetaTitleAndDescription />
      <div className="container">
        <div className="educator_div_section">
          <div>
            <h2 style={{ marginTop: "20px" }}>Educators Forum</h2>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/forum">Forums</Breadcrumb.Item>
              <Breadcrumb.Item active>Educators</Breadcrumb.Item>
            </Breadcrumb>

            
          </div>
          <div className="educator_and_search_section">
            
          </div>
        </div>
        {loading?<div className="loading-spinner">
        <FontAwesomeIcon icon={faSpinner} spin fontSize="60px" />
      </div> :(<div className="educator_section">
          {forum.length > 0 ? (
            <div>
              <Table responsive="lg" striped bordered hover >
                <thead>
                  <tr>
                    <th>Posts</th>
                    <th>Rating</th>
                    <th>Views</th>
                    <th>By</th>
                  </tr>
                </thead>
                <tbody>
                  {forum.map((forumsdata,index) => {
                    return (
                      <tr >
                        <td className="educator_text">
                          <div className="educator_list" key={index}>
                            <img src={forumsdata.image_url} alt="user" className="forum_image"/>
                            <div>
                              <p onClick={()=>handleDetail(forumsdata.unique_id, forumsdata.slug)} className="para_title">
                                {forumsdata.title}
                              </p>
                              <p>{formatDate(forumsdata.created_at)}</p>
                            </div>
                          </div>
                          <div>
                            <span>
                              <Badge bg="danger">
                                {forumsdata.is_featured}
                              </Badge>
                            </span>
                          </div>
                        </td>
                        <td className="educator_text1">{forumsdata.rating}</td>
                        <td className="educator_text1">{forumsdata.views}</td>
                        <td className="educator_text1">{forumsdata.user_name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="new_topic_section">
                <button className="post_btn" onClick={handlePostNewTopic} >POST NEW TOPIC</button>
              </div>
            </div>
          ) : (educator &&
            <p className="educator_no_data_found">No Data Found</p>
          )}

          <div className="educator_pagination">
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onNextClick={handleNextClick}
                onPrevClick={handlePrevClick}
                onPageClick={handlePageClick}
              />
            )}
          </div>
        </div>)}
      </div>

      <Footer />
    </>
  );
};