import axios from "axios";
import React, { useState } from "react";
import { Alert, Form, Modal } from "react-bootstrap";
import { Footer } from "./Footer";
import { bwcDealsApi } from "./Api";
import { useNavigate } from "react-router-dom";
import "./ForgotPassword.css";
import Cookies from "js-cookie";
import { MetaTitleAndDescription } from "./MetaTitleAndDescription";

export const ForgotPasswordModal = (props) => {
  document.title = "- BwcDeals";
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const initialValues = { email: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const navigate = useNavigate();
  const csrftoken =  Cookies.get('csrftoken');
  const handleEmailFocus = () => {
    setIsEmailFocused(true);
  };

  const handleEmailBlur = () => {
    setIsEmailFocused(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });
    setFormErrors("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (formValues.email !== "") {
      try {
        const response = await axios.post(
          `${bwcDealsApi}/forgot_password`,
          formValues,
          {
            headers: {
              "Content-Type": "application/json",
              'X-CSRFToken': csrftoken
            },
          }
        );

        if (response.data.status === "true") {
          setError(null);
          setSuccess("Password sent successfully to this email Id.");

          setTimeout(() => {
            setSuccess(null);
            navigate("/");
          }, 3000);
        }
      } catch (err) {
        setError(err.response.data.message);
        setSuccess(null);
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };

  const validate = (values) => {
    const errors = {};

    if (values.email === "") {
      errors.email = "Email is required !";
    }

    return errors;
  };

  return (
    <>
    <MetaTitleAndDescription />
      <div className="container-fluid">
        <div className="row justify-content-md-center">
          <div className="col-xxl-4 col-xl-6 col-md-8 col-lg-7 col-sm-11 forgotPasswordForm">
            <div class="container mt-4 mb-4">
              <div class="login-container">
                {success && (
                  <Alert variant="success" style={{ fontSize: "20px" }}>
                    {success}
                  </Alert>
                )}
                {error && (
                  <Alert variant="danger" style={{ fontSize: "20px" }}>
                    {error == "Not Email id Found."
                      ? "Email Id not found."
                      : error}
                  </Alert>
                )}

                <form onSubmit={handleSubmit}>
                  <Form>
                    <p className="login_text">Forgot Password</p>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <div className="mb-3">
                        <Form.Control
                          type="email"
                          className="input_box"
                          placeholder="Email"
                          onFocus={handleEmailFocus}
                          onBlur={handleEmailBlur}
                          style={{
                            border: isEmailFocused
                              ? "1px solid rgba(53, 63, 102, 1)"
                              : "1px solid #ced4da",
                            boxShadow: isEmailFocused ? "none" : "none",
                          }}
                          name="email"
                          value={formValues.email}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <p className="blank_message">{formErrors.email}</p>
                      </div>
                    </Form.Group>
                  </Form>

                  <button type="submit" className="login_button">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
