import React, { useEffect, useState } from "react";
import { Alert, Breadcrumb } from "react-bootstrap";
import facebook from "../Pictures/fb.png";
import twitter from "../Pictures/twitter.png";
import pintrest from "../Pictures/pintrest.png";
import linkedin from "../Pictures/linkedin.png";
import like from "../Pictures/like.png";
import already_like from "../Pictures/already_like.png";

import unlike from "../Pictures/unlike.png";
import already_unlike from "../Pictures/already_dislike.png";
import "./EducatorDetail.css";
import { Footer } from "./Footer";
import axios from "axios";
import { bwcDealsApi } from "./Api";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Comment } from "./Comment";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { MetaTitleAndDescription } from "./MetaTitleAndDescription";
import DOMPurify from "dompurify";

export const EducatorDetail = () => {
  // document.title="- BwcDeals"
  const [data, setData] = useState("");
  const { uni_id, slug } = useParams();
  const navigate = useNavigate();
  const [social, setSocial] = useState([]);
  const [isLiked, setIsLiked] = useState(false);
  const token = JSON.parse(
    Cookies.get("loginTokenBwcDeals") === undefined
      ? null
      : Cookies.get("loginTokenBwcDeals")
  );
  const [comments, setComments] = useState("");
  const [newComment, setNewComment] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [profile, setProfile] = useState([]);
  const [userImage, setUserImage] = useState("");
  const [dataStatus, setDataStatus] = useState(false);
  const [loading,setLoading]= useState(true)
  const csrftoken =  Cookies.get('csrftoken');
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .post(
        `${bwcDealsApi}/user_dashboard`,
        {
          user_id: (token && token.response.user_id) || "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': csrftoken
          },
        }
      )

      .then((res) => {
        setProfile(res.data.response.user_data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    axios
      .get(`${bwcDealsApi}/social`)
      .then((res) => {
        setSocial(res.data.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    setIsLiked(true);
    setLoading(true)
    if (token) {
      axios
        .post(`${bwcDealsApi}/product_details`, {
          user_id: token && token.response.user_id,
          unique_id: uni_id,
          slug: slug,
          type: "g",
        },
        {
          headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': csrftoken
          },
        }
        )
        .then((res) => {
          // console.log("Res with login", res);
          setLoading(false)
          setUserImage(res.data.response.comments_user_image);
          setData(res.data.response.product_details);
          setNewComment(res.data.response.comments);
        })
        .catch((error) => {
          // console.log("Error with login",error.response.data.status)
          if (error.response.data.status == "false") {
            // console.log("Error with login",error.response.data.status)
            setLoading(false)
            setDataStatus(true);
          }
        });
    } else {
      axios
        .post(`${bwcDealsApi}/product_details`, {
          unique_id: uni_id,
          slug: slug,
          type: "g",
        },
        {
          headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': csrftoken
          },
        }
        )
        .then((res) => {
          setLoading(false)
          // console.log("Res without login", res);
          setUserImage(res.data.response.comments_user_image);
          setData(res.data.response.product_details);
          setIsLiked(true);
        })
        .catch((error) => {
          if (error.response.data.status == "false") {
            setLoading(false)
            setDataStatus(true);
          }
        });
    }
  }, []);

  const handlePostNewTopic = () => {
    if (token === "null") {
      navigate("/login");
    } else {
      navigate("/submitdeal");
    }
  };
  const handleLike = () => {
    if (token) {
      if (+data.is_liked) {
        axios
          .post(`${bwcDealsApi}/product_like_unlike`, {
            user_id: token && token.response.user_id,
            post_id: data.post_id,
            post_type: data.post_type,
            btn_type: "like",
          },
          {
            headers: {
              "Content-Type": "application/json",
              'X-CSRFToken': csrftoken
            },
          }
          )
          .then((res) => {
            setIsLiked(true);
            window.location.reload();
          })
          .catch((error) => {});
      }
    } else {
      navigate("/login");
    }
  };
  const handleUnLike = () => {
    if (token) {
      if (data.is_liked) {
        axios
          .post(`${bwcDealsApi}/product_like_unlike`, {
            user_id: token && token.response.user_id,
            post_id: data.post_id,
            post_type: data.post_type,
            btn_type: "unlike",
          },
          
          {
            headers: {
              "Content-Type": "application/json",
              'X-CSRFToken': csrftoken
            },
          })
          .then((res) => {
            setIsLiked(true);
            window.location.reload();
          })
          .catch((error) => {});
      }
    } else {
      navigate("/login");
    }
  };

  const handleNewComment = () => {
    if (token !== null) {
      if (comments.trim() !== "") {
        let collectedData = {
          user_id: token && token.response.user_id,
          post_id: data.post_id,
          post_type: data.post_type,
          comment_id: "",
          comment_text: comments,
        };

        axios
          .post(`${bwcDealsApi}/product_add_comment`, collectedData,{
            headers: {
              "Content-Type": "application/json",
              'X-CSRFToken': csrftoken
            },
          })
          .then((res) => {
            setNewComment("");
            setErrorMessage("");
            window.location.reload();
          })
          .catch((error) => {});
      } else {
        setErrorMessage("Please enter a comment before posting.");
      }
    } else {
      navigate("/login");
    }
  };

  const pos = data.post_id;
  const type = data.post_type;
  let url = `${process.env.REACT_APP_FRONTEND_API}/educator/${uni_id}/${slug}`;

  const metadata = {
    title: data.title || "- BwcDeals",
    description: DOMPurify.sanitize(data.description, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] }) || "Product Description",
    image: data.image_url || "../../public/new_logo_bwcdeals.jpg",
    url: `${process.env.REACT_APP_FRONTEND_API}/educator/${uni_id}/${slug}`,
  };

// console.log(DOMPurify.sanitize(data.description, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] }))
  const handlePintrest = () => {
    const description = encodeURIComponent(
      data && data.title
    );
    const imageUrl = encodeURIComponent(
      data && data.image_url
    );
    const url = encodeURIComponent(
      `${process.env.REACT_APP_FRONTEND_API}/educator/${uni_id}/${slug}`
    );

    const pinterestShareUrl = `https://in.pinterest.com/pin-builder/?description=${description}&media=${imageUrl}&url=${url}`;

    window.open(pinterestShareUrl, "_blank", "width=600,height=400");
  };
  return (
    <>
    {/* <MetaTitleAndDescription /> */}
      <Helmet>
        <title>{metadata.title}</title>
        <meta property="og:title" content={metadata.title} />
        <meta property="og:description" content={metadata.description} />
        <meta property="og:image" content={metadata.image} />
        <meta property="og:url" content={metadata.url} />

        {/* Pinterest */}
        <meta
          name="p:domain_verify"
          content="your Pinterest domain verification code"
        />

        {/* Twitter */}
        <meta name="twitter:card" content={metadata.image} />
        <meta name="twitter:title" content={metadata.title} />
        <meta name="twitter:description" content={metadata.description} />
        <meta name="twitter:image" content={metadata.image} />

        {/*  linkedin */}

        <meta
          prefix="og: http://ogp.me/ns#"
          property="og:title"
          content={metadata.title}
        />
        <meta
          prefix="og: http://ogp.me/ns#"
          property="og:description"
          content={metadata.description}
        />
        <meta
          prefix="og: http://ogp.me/ns#"
          property="og:image"
          content={metadata.url}
        />
      </Helmet>
      {dataStatus ? (
        <div className="container">
        <p className="store_no_data_found">
          <Alert variant="primary">No Data Found</Alert>
        </p></div>
      ) : (
        <div className="container">
          {loading?<div className="loading-spinner">
        <FontAwesomeIcon icon={faSpinner} spin fontSize="60px" />
      </div> :<><h2 style={{ marginTop: "20px" }}>{data.title}</h2>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/forum">Forums</Breadcrumb.Item>
            <Breadcrumb.Item href="/educator">Educators</Breadcrumb.Item>
            <Breadcrumb.Item active>{data.title}</Breadcrumb.Item>
          </Breadcrumb>

          <div className="clear_list">
            <div className="list_img">
              <img
                src={data.image_url}
                alt={data.title}
                className="listing_img"
              />
            </div>

            <div className="list_text">
              <p className="list">{data.title}</p>
              <div className="social_media_div_grab_deal">
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    url
                  )}&text=Sharing`}
                  target="_blank"
                >
                  <img
                    src={facebook}
                    alt="facebook"
                    className="social_list_product"
                    //  onClick={handleFacebook}
                  />
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    url
                  )}&text=Sharing`}
                  target="_blank"
                >
                  <img
                    src={twitter}
                    alt="twitter"
                    className="social_list_product"
                  />
                </a>
                <span >
                  <img
                    src={pintrest}
                    alt="pintrest"
                    className="social_list_product"
                    onClick={handlePintrest}
                  />
                </span>
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                    url
                  )}`}
                  target="_blank"
                >
                  <img
                    src={linkedin}
                    alt="linkedin"
                    className="social_list_product"
                  />
                </a>
              </div>
              <div className="list_like">
                {/* src={isLiked ? unlikedImage : unlike} */}

                {isLiked && (
                  <img
                    src={
                      token
                        ? data.is_liked == +2
                          ? like
                          : data.is_liked == 1
                          ? already_like
                          : like
                        : like
                    }
                    alt="like"
                    onClick={handleLike}
                    className="like"
                  />
                )}
                <img
                  src={
                    token
                      ? data.is_liked == +2
                        ? unlike
                        : data.is_liked == -1
                        ? already_unlike
                        : unlike
                      : unlike
                  }
                  alt="unlike"
                  onClick={handleUnLike}
                  className="like"
                />
              </div>
            </div>
          </div>

          <p className="about_deal">About Deal Discussion</p>

          {/* {data.description} */}

          <div
            dangerouslySetInnerHTML={{ __html: data.description }}
            className="paragraph_description"
          />

          <div className="comment_sectio">
            {newComment && newComment.length > 0 && (
              <p className="comment_text">Comments</p>
            )}
            {newComment &&
              newComment.map((elem) => {
                return (
                  <div key={elem.id} className="comment_div_section">
                    <Comment
                      elem={{ ...elem, product_id: pos, product_type: type }}
                    />
                  </div>
                );
              })}
          </div>

          {/* Comment section */}
          <div className="comment_box">
            <p className="comment_text">Leave a Comment</p>
            <div className="user_img">
              <div>
                <img
                  src={userImage && userImage[0].user_image}
                  alt="user"
                  className="user_image_profile"
                />
              </div>

              <div className="comment-section">
                {/* <h2>Leave Comments</h2> */}

                <div className="new-comment">
                  <textarea
                    type="text"
                    // value={newComment}
                    className="text_area"
                    onChange={(e) => {
                      setComments(e.target.value);
                      setErrorMessage("");
                    }}
                    placeholder="Add a comment..."
                  />
                  {errorMessage && (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  )}
                  <div>
                    <button onClick={handleNewComment} className="comment_btn">
                      Comment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="btn_post">
            <button className="post_topic_btn" onClick={handlePostNewTopic}>
              POST NEW TOPIC
            </button>
          </div></>}
        </div>
      )}
      <Footer />
    </>
  );
};
