import React, { useEffect, useState } from "react";
import { FeaturedStoreCarousel } from "./FeaturedStoreCarousel";
import { Button, Offcanvas } from "react-bootstrap";
import { FilterPrice } from "./FilterPrice";
import { FilterDiscount } from "./FilterDiscount";
import "./OffCanvas.css";

export const Canvas = ({
  isCanvasOpen,
  handleClose,
  visit,
  store,
  sendDataToParent,
  sendDiscountData,
  selectedCategories,
  handleCategoryChange,
  priceRange,
  filteredPriceRangeFirstTime,
  filteredDiscountRangeFirstTime,
  discountRange
}) => {
// console.log("Visit",visit)
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filteredPriceRange, setFilteredPriceRange] = useState([
    filteredPriceRangeFirstTime[0],
    filteredPriceRangeFirstTime[1],
  ]);
  const [filteredDiscountRange, setFilteredDiscountRange] = useState([
    filteredDiscountRangeFirstTime[0],
    filteredDiscountRangeFirstTime[1],
  ]);


  
  useEffect(() => {
    if (store && store.price_filter) {
      setFilteredPriceRange([
        store.price_filter[0].min_price,
        store.price_filter[0].max_price,
      ]);
    }
    if (store && store.discount_filter) {
      setFilteredDiscountRange([
        store.discount_filter[0].min_discount,
        store.discount_filter[0].max_discount,
      ]);
    }
  }, [store]);

  const handleFilter = (priceRange) => {

    setFilteredPriceRange(priceRange);
    sendDataToParent(priceRange);
  };

  const handledisFilter = (discountRange) => {

    setFilteredDiscountRange(discountRange);

    sendDiscountData(discountRange);
  };

  const handleVisit = (visit) => {

    if(visit.link==null){
      window.open(visit.slug, "_blank");
    }else{
      window.open(visit.link, "_blank");
    }
    
  };


  return (
    <Offcanvas
      show={isCanvasOpen && !filtersApplied}
      onHide={handleClose}
      placement="end"
      className="canvas"
    >
      <Offcanvas.Header closeButton>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="carousel_card">
          <FilterPrice
            onFilter={handleFilter}
            store={store}
            selectedPriceRange={filteredPriceRange}
            priceRangeChanged={priceRange}
            filteredPriceRangeFirstTime={filteredPriceRangeFirstTime}
          />
        </div>
        <div className="carousel_card">
          <FilterDiscount
            onFilter={handledisFilter}
            store={store}
            selectedDiscountRange={filteredDiscountRange}
            filteredDiscountRangeFirstTime={filteredDiscountRangeFirstTime}
            discountRangeChanged={discountRange}
          />
        </div>
        <div className="carousel_card">
          <span>
            <img
              src={visit.image_url}
              alt={visit.title}
              className="image_store1"
            ></img>
          </span>
          <p>{visit.title}</p>
          <Button variant="primary" onClick={() => handleVisit(visit)}>
            Visit Store
          </Button>
        </div>
        <div className="carousel_card">
          <FeaturedStoreCarousel />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
