import React, { useEffect, useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { bwcDealsApi } from "./Api";

export const Footer = () => {
  const [general, setGeneral] = useState([]);
  const [social, setSocial] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    axios
      .get(`${bwcDealsApi}/general_settings`)
      .then((res) => {
       
        setGeneral(res.data.footer_setting);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    axios
      .get(`${bwcDealsApi}/social`)
      .then((res) => {
        setSocial(res.data.data);
      })
      .catch((error) => {
      });
  }, []);

  let year = new Date().getFullYear();
  return (
    <div className="footer_part">
      <div className="footer_container">
        <div className="inner_footer_box container">
          <div className="left_div_top_footer">
            <p>{general[0]?.Copyright_Text}</p>
            <div className="play_store">
              {general[0]?.play_store_image && (
                <a href={general[0].Playstore_URL} target="_blank">
                  <img
                    src={general[0].play_store_image}
                    alt="google_pay"
                    className="social_media_image"
                  />
                </a>
              )}

              {general[0]?.play_store_image && (
                <a href={general[0].App_Store_URL} target="_blank">
                  <img
                    src={general[0].app_store_image}
                    alt="google_pay"
                    className="social_media_image"
                  />
                </a>
              )}
            </div>
          </div>
          <div className="privacy_policy_div_footer">
            <a href="/privacy-policy" className="link_tag" target="_blank">
              Privacy Policy
            </a>
            <div className="image_box_social_media_section_footer">
              <span>Follow us on - </span>

              {social &&
                social.length > 0 &&
                social.map((item) => {
                  return (
                    <a
                      href={item.social_url}
                      target="_blank"
                      key={item.social_url}
                    >
                      <i
                        className={item.social_image}
                        style={{ fontSize: "20px", color: "white" }}
                      ></i>
                    </a>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_footer">
        <p>
          {" "}
          © {year}{" "}
          <a
            href="https://bwcdeals.com/"
            target="_blank"
            className="footer_link"
          >
            BwcDeals
          </a>{" "}
          | Powered by{" "}
          <a
            href="https://www.hexagoninfosoft.com/"
            target="_blank"
            className="footer_link"
          >
            Hexagon Infosoft
          </a>{" "}
        </p>
      </div>
    </div>
  );
};
