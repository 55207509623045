import React from 'react';
import './Pagination.css';

export const Pagination = ({ currentPage, totalPages, onNextClick, onPrevClick, onPageClick }) => {
  const getPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 4) {
        for (let i = 1; i <= 5; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - 3) {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  return (
    <div className="pagination">
      <button onClick={onPrevClick} disabled={currentPage === 1}>
        Previous
      </button>
      {getPageNumbers().map((page, index) => (
        <span
          key={index}
          className={page === '...' ? 'ellipsis' : page === currentPage ? 'active' : ''}
          onClick={() => (typeof page === 'number' ? onPageClick(page)
 : null)}
        >
          {page}
        </span>
      ))}
      <button className='next_btn' onClick={onNextClick} disabled={currentPage === totalPages}>
        Next
      </button>
    </div>
  );
};