import React, { useEffect, useState } from "react";
import "./Home.css";
import { Carousel } from "../Components/Carousel";
import { Footer } from "../Components/Footer";
import axios from "axios";
import { Product } from "../Components/Product";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { bwcDealsApi } from "../Components/Api";
import Cookies from "js-cookie";
import { Alert } from "react-bootstrap";
import { MetaTitleAndDescription } from "../Components/MetaTitleAndDescription";


export const Home = () => {
  const [trending, setTrending] = useState([]);
  const [recentDeals, setRecentDeals] = useState([]);
  const [data, setData] = useState([]);

  const [trendingSelected, setTrendingSelected] = useState([]);
  const [recentDealsSelected, setRecentDealsSelected] = useState([]);

  const [page, setPage] = useState(1);
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
 
  const [currentPage, setCurrentPage] = useState(1);
  const [detail, setDetail] = useState("");
  const itemsPerPage = 12;
 
  const [merchant, setMerchant] = useState([]);
  const [category, setCategory] = useState([]);
  const [loadMoreHome, setLoadMoreHome] = useState(false);
  const [homeStatus, setHomeStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(false);

  const [recentStatus, setRecentStatus] = useState(false);
  const [trendingStatus, setTrendingStatus] = useState(false);

  const [loadMoreSelected, setLoadMoreSelected] = useState(true);
  const [homeLoadMoreShow, setHomeLoadMoreShow] = useState(true);




  const [homeLoading,setHomeLoading]= useState(false);
  const [selectedLoadig,setSelectedLoading]= useState(false)
  const [loading,setLoading]=useState(true)

  const token = JSON.parse(
    Cookies.get("loginTokenBwcDeals") === undefined ? null : Cookies.get("loginTokenBwcDeals")
  );

 
const csrftoken =  Cookies.get('csrftoken');

 document.title="- BwcDeals";

  const homeApi = () => {
    setLoading(true)
    axios
      .post(`${bwcDealsApi}/home`, { user_id: token && token.response.user_id },{
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      })
      .then((res) => {
       
        setLoading(false)
        setSelectedLoading(false)
        setData(res.data.teachers_wishlist);
        setTrending(res.data.trending_deals.deals_arr);
        setRecentDeals(res.data.recent_deals.recent_arr);
       
        setRecentDealsSelected([]);
        setTrendingSelected([]);
        setSelectedStatus(false);
        if (
          res.data.recent_deals.recent_arr &&
          res.data.recent_deals.recent_arr.length === 0
        ) {
          setRecentStatus(true);
        }
        if (
          res.data.trending_deals.deals_arr &&
          res.data.trending_deals.deals_arr.length === 0
        ) {
          setTrendingStatus(true);
        }
      })
      .catch((error) => {
      
        setLoading(false)
      });
  };

  useEffect(() => {
    homeApi();
  }, []);

  // select option part

  useEffect(() => {
    axios
      .get(`${bwcDealsApi}/stores_list`)
      .then((res) => {
        setMerchant(res.data.response.selected_data);
      })
      .catch();
  }, []);

  useEffect(() => {
    axios
      .get(`${bwcDealsApi}/category_list`)
      .then((res) => {
        setCategory(res.data.response.selected_data);
      })
      .catch();
  }, []);

  const loadMore = () => {
    setLoadMoreHome(true);
    setCurrentPage((page) => page + 1);
    setHomeLoading(true)
    axios
      .post(
        `${bwcDealsApi}/recent_deal_pagination`,
        { page: currentPage }, // Send the calculated page number in the request
        {
          headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': csrftoken
          },
        }
      )

      .then((res) => {
        setHomeLoading(false)
        setSelectedLoading(false)
        if (
          res.data.recent_deals_pagination.current_page ==
          res.data.recent_deals_pagination.last_page
        ) {
          setHomeLoadMoreShow(false);
        }
        setRecentDeals([...recentDeals, ...res.data.recent_deals]);
      })
      .catch((error) => {setHomeLoadMoreShow(false)});
  };

  const loadMoreOptionSection = () => {
    setLoadMoreHome(false);
    setSelectedLoading(true)
     setPage((page) => page + 1);
    axios
      .post(
        `${bwcDealsApi}/category_store_filter_home`,
        {
          store_id: selectedMerchant,
          category_id: selectedCategory,
          page: page + 1,
        },
        {
          headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': csrftoken
          },
        }
      )
      .then((res) => {
        setSelectedLoading(false)
        if (res.data.pagination.current_page == res.data.pagination.last_page) {
          setLoadMoreSelected(false);
        }
        setRecentDealsSelected([
          ...recentDealsSelected,
          ...res.data.recent_deals_data,
        ]);
      })
      .catch((error) => {setSelectedLoading(false);});
  };
  const fetchDataByStoreAndCategory = (storeId, categoryId) => {
    setLoadMoreHome(false);

    axios
      .post(
        `${bwcDealsApi}/category_store_filter_home`,
        { store_id: storeId, category_id: categoryId, page: 1 },
        {
          headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': csrftoken
          },
        }
      )
      .then((res) => {
        setRecentDealsSelected(res.data.recent_deals_data);
        setTrendingSelected(res.data.trending_deals_arr);

        if (res.data.pagination.current_page == res.data.pagination.last_page) {
          setLoadMoreSelected(false);
        } else {
          setLoadMoreSelected(true);
        }
        setDetail(res.data.pagination);
        setRecentDeals([]);
        setTrending([]);

        if (recentDeals.length > 1) {
          setPage(1);
        }
      })
      .catch((err)=>{

      });
  };

  const handleChangeMerchant = (e) => {
    const selectedValue = e.target.value;
    setSelectedMerchant(selectedValue);
    setPage(1);
    performCombinedOperation(selectedValue, selectedCategory);
  };

  const handleChangeCategory = (e) => {
    const selectedValue = e.target.value;
    setSelectedCategory(selectedValue);
    setPage(1);
    performCombinedOperation(selectedMerchant, selectedValue);
  };

  const performCombinedOperation = (merchantValue, categoryValue) => {
    setPage(1);
    if (merchantValue == "" && categoryValue == "") {
      setCurrentPage(1);

      // setHomeStatus(false);
      setSelectedStatus(false);
      // setData([])
      homeApi();
    } else {
      setHomeStatus(false);
      setSelectedStatus(true);
      fetchDataByStoreAndCategory(merchantValue, categoryValue);

      // setPage(1)
    }
  };

  
  return (
    <div>
      <MetaTitleAndDescription />
      <div className="container">
        
        <div className="category_and_search_section">
          <div className="select_section">
            <div className="select_container">
              <select
                className="select_option_value"
                onChange={handleChangeMerchant}
              >
                {/* <div className="merchant_div"> */}
                <option value="" className="select_option_value1">By Merchant</option>
                {merchant &&
                  merchant.map((item) => (
                    <option value={item.store_id} className="select_option_value1" >{item.title}</option>
                  ))}
                  {/* </div> */}
              </select>

              
            </div>
            <div className="select_container">
              <select
                className="select_option_value"
                onChange={handleChangeCategory}
              >
                <option value="">By Category</option>
                {category &&
                  category.map((item) => (
                    <option value={item.category_id}>{item.title}</option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="banner_background">
        <div className="container">
          <p>
            <span>Teachers </span> Wishlist
          </p>

          <div>
            <Carousel data={data} />
          </div>
        </div>
      </div>
      {loading?<div className="spinnerHome loading_home">
        <FontAwesomeIcon icon={faSpinner} spin fontSize="60px" />
      </div> :
      <div className="container">
        {trending && trending.length > 0 && (
          <div className="trending_deals">
            <p className="trending_para">
              <span>Trending </span> Deals
            </p>
            {
              <div className="product_grid">
                {trending &&
                  trending.map((product) => (
                    <Product item={product} key={product.coupon_id} />
                  ))}
              </div>
            }
          </div>
        )}
        {homeStatus && trending && trending.length === 0 && (
          <>
            <p className="trending_para">
              <span>Trending </span> Deals
            </p>

            <Alert variant="primary">No Data Found</Alert>
          </>
        )}
        {recentDeals && recentDeals.length > 0 && (
          <div className="recent_deals">
            <p className="recent_para">
              <span>Recent </span> Deals
            </p>
            {
              <div className="recent_grid">
                {recentDeals &&
                  recentDeals.map((product) => (
                    <Product item={product} key={product.coupon_id} />
                  ))}
              </div>
            }

            {homeLoading ? (
              <div className="spinnerHome" >
                <FontAwesomeIcon icon={faSpinner} spin fontSize="60px" />
              </div> // Show loading indicator when loading is true
            ) : (
             
              recentDeals &&
              recentDeals.length > 0 &&
              homeLoadMoreShow && (
                 <div className="load_more" onClick={loadMore}>
                  LOAD MORE
                </div>
                
              )
              // )
            )}
          </div>
        )}

        {homeStatus && recentDeals && recentDeals.length === 0 && (
          <>
            <p className="trending_para">
              <span>Recent </span> Deals
            </p>
            <Alert variant="primary">No Data Found</Alert>
          </>
        )}

        {trendingSelected && trendingSelected.length > 0 && (
          <div className="trending_deals">
            <p className="trending_para">
              <span>Trending </span> Deals
            </p>
            {
              <div className="product_grid">
                {trendingSelected &&
                  trendingSelected.map((product) => (
                    <Product item={product} key={product.coupon_id} />
                  ))}
              </div>
            }
          </div>
        )}
        {selectedStatus &&
          trendingSelected &&
          trendingSelected.length === 0 && (
            <>
              <p className="trending_para">
                <span>Trending </span> Deals
              </p>
              <Alert variant="primary">No Data Found</Alert>
            </>
          )}

        {recentDealsSelected && recentDealsSelected.length > 0 && (
          <div className="recent_deals">
            <p className="recent_para">
              <span>Recent </span> Deals
            </p>
            {
              <div className="recent_grid">
                {recentDealsSelected &&
                  recentDealsSelected.map((product) => (
                    <Product item={product} key={product.coupon_id} />
                  ))}
              </div>
            }

            {selectedLoadig ? (
              <div className="spinnerHome">
                <FontAwesomeIcon icon={faSpinner} spin fontSize="60px" />
              </div> // Show loading indicator when loading is true
            ) : 
            selectedMerchant !== "" || selectedCategory !== "" ? (
              recentDealsSelected &&
              recentDealsSelected.length > 0 &&
              loadMoreSelected && (
                <div className="load_more" onClick={loadMoreOptionSection}>
                  LOAD MORE
                </div>
              )
            ) : (
              recentDeals &&
              recentDeals.length >= 15 && (
                <div className="load_more" onClick={loadMore}>
                  LOAD MORE
                </div>
              )
            )
            // )
            }
          </div>
        )}

        {selectedStatus &&
          recentDealsSelected &&
          recentDealsSelected.length === 0 && (
            <>
              <p className="trending_para">
                <span>Recent </span> Deals
              </p>
              <Alert variant="primary">No Data Found</Alert>
            </>
          )}
      </div>}
      
      <Footer />
    </div>
  );
};
