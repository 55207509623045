import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { bwcDealsApi } from "./Api";
import { Helmet } from "react-helmet";
import axios from "axios";

export const MetaTitleAndDescription = () => {
  const [general, setGeneral] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    axios
      .get(`${bwcDealsApi}/general_settings`)
      .then((res) => {
        setGeneral(res.data.general_setting);
      })
      .catch((error) => {});
  };


  return <>{(general&& general.length>0) && 
    <Helmet>
    
        <meta name="keywords" content={general && general[0].Website_Keywords} />
        <meta name="description" content={(general && general[0].Website_Description) || "Like Deals and supporting Teachers? Then you are at the right place!!"}/>
        
    </Helmet>}
    </>;
};
