import React, { useEffect, useState } from "react";
import { Alert, Breadcrumb } from "react-bootstrap";
import { Footer } from "./Footer";
import { bwcDealsApi } from "./Api";
import axios from "axios";
import { Pagination } from "./Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { Deal } from "./Deal.jsx";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { SearchOffCanvas } from "./SearchOffCanvas";
import { MetaTitleAndDescription } from "./MetaTitleAndDescription";

export const NewDeals = () => {
  document.title = "- BwcDeals";
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const [currentPage, setCurrentPage] = useState((page === null || page === undefined || page === "")?1:Number(page));
  const itemsPerPage = 15;
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [isCanvasOpen, setIsCanvasOpen] = useState(false);
  const [filter, setFilter] = useState({});
  const [priceRange, setPriceRange] = useState([]);
  const [filteredPriceRange, setFilteredPriceRange] = useState([0, 0]);
  const token = JSON.parse(
    Cookies.get("loginTokenBwcDeals") === undefined
      ? null
      : Cookies.get("loginTokenBwcDeals")
  );
  const csrftoken =  Cookies.get('csrftoken');
  const [loading, setLoading] = useState(true);
  const [isData, setIsData] = useState(false);
  const [pagination, setPagination] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState([]);
  // const [firstLoad, setFirstLoad] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [isAsideOpen, setIsAsideOpen] = useState(false);
  const [visit, setVisit] = useState({});

  const [discountRange, setDiscountRange] = useState([]);
  const [filteredDiscountRange, setFilteredDiscountRange] = useState([0, 0]);

  const [searchTerm1, setSearchTerm1] = useState("");
  const [filterFirstLoad, setFilterFirstLoad] = useState(false);

  const [homeFilter, setHomeFilter] = useState(true);

  const [pfs, setPfs] = useState(true);

  const [firstStorePrice, setFirstStorePrice] = useState([0, 0]);
  const [firstStoreDiscount, setFirstStoreDiscount] = useState([0, 0]);
  const [filterPagination, setFilterPagination] = useState(false);
  const [filStatus,setFilStatus]= useState("")

  useEffect(() => {
    if (page === null || page === undefined || page === "") {
      setCurrentPage(1);
    } else {
      setCurrentPage(Number(page));
    }
  }, [page]);


  const firstData = () => {
    setHomeFilter(true)
    axios
      .post(`${bwcDealsApi}/category_page`, {
        slug: "new-deals",
        type: "d",
        page: currentPage,
      },
      {
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      }
      )
      .then((r) => {
        setLoading(false);
        setData(r.data.response.deal_data);
        setVisit(r.data.response.selected[0]);
        setFilter(r.data.response);
        setPagination(r.data.response.deal_data_pagination.total);
        if (r.data.response.deal_data.length === 0) {
          setData([]);
          setIsData(true);
          setFilterStatus(false);
        } else {
          setIsData(false);
          setFilterStatus(true);
        }
        setFilteredPriceRange([
          r.data.response.price_filter[0].min_price,
          r.data.response.price_filter[0].max_price,
        ]);
        setPriceRange([
          r.data.response.price_filter[0].min_price,
          r.data.response.price_filter[0].max_price,
        ]);
        setDiscountRange([
          r.data.response.discount_filter[0].min_discount,
          r.data.response.discount_filter[0].max_discount,
        ]);
        setFilteredDiscountRange([
          r.data.response.discount_filter[0].min_discount,
          r.data.response.discount_filter[0].max_discount,
        ]);
      })
      .catch((e) => {
      });
  };

  const searchFunctionFilter = (rest) => {
    setFilterFirstLoad(true)
    axios
      .post(`${bwcDealsApi}/filter`, rest, {
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      })
      .then((res) => {
        setPriceRange([
          res.data.response.price_filter[0].min_price,
          res.data.response.price_filter[0].max_price,
        ]);
        setDiscountRange([
          res.data.response.discount_filter[0].min_discount,
          res.data.response.discount_filter[0].max_discount,
        ]);
        setFirstStorePrice([
          res.data.response.price_filter[0].min_price,
          res.data.response.price_filter[0].max_price,
        ]);
        setFirstStoreDiscount([
          res.data.response.discount_filter[0].min_discount,
          res.data.response.discount_filter[0].max_discount,
        ]);

        setFilteredPriceRange([
          res.data.response.price_filter[0].min_price,
          res.data.response.price_filter[0].max_price,
        ]);
        setFilteredDiscountRange([
          res.data.response.discount_filter[0].min_discount,
          res.data.response.discount_filter[0].max_discount,
        ]);
        if (res.data.status === "true") {
          setLoading(false);
          setData(res.data.response.deal_data);

          if (
            res.data.response.deal_data &&
            res.data.response.deal_data.length > 0
          ) {
            setIsData(false);
          } else {
            setIsData(true);
          }
          setPagination(res.data.response.deal_data_pagination.total);
        } else {
          setData([]);
          setIsData(true);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const searchFunction = (rest) => {
    setFilterFirstLoad(true)
   
    axios
      .post(`${bwcDealsApi}/filter`, rest, {
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      })
      .then((res) => {
        setData(res.data.response.deal_data);
        setPagination(res.data.response.deal_data_pagination.total);
        if (res.data.status == "true") {
          setLoading(false);

          if (
            res.data.response.deal_data &&
            res.data.response.deal_data.length > 0
          ) {
            setIsData(false);
          } else {
            setIsData(true);
          }
        }
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  useEffect(() => {
    if (homeFilter) {
      // console.log("Home filter")
      setLoading(true);
      firstData();
     
      setFilterFirstLoad(false);
      
    } else {
      setLoading(true);
      if (filterFirstLoad) {
        setHomeFilter(false);
        // if (selectedCategories.length !== 0) {
          let dataStore = selectedCategories.join(",");
          let dataGet = {
            store_id: dataStore,
            page: currentPage,
            min_discount: discountRange[0],
            max_discount: discountRange[1],
            min_price: priceRange[0],
            max_price: priceRange[1],
            filter_type:filStatus
          };
          searchFunction(dataGet);
        // } 
      }
    }
  }, [currentPage]);


  const totalPages = Math.ceil(pagination / itemsPerPage);

  const handleNextClick = () => {
    const nextPage = currentPage + 1;
    navigate(`?page=${nextPage}`);
  };

  const handlePrevClick = () => {
    const prevPage = currentPage - 1;
    if (prevPage > 0) {
      navigate(`?page=${prevPage}`);
    }
  };

  const handlePageClick = (pageNumber) => {
    navigate(`?page=${pageNumber}`);
  };

  const handleEducatorsPost = () => {
    if (token === "null") {
      navigate("/login");
    } else {
      navigate("/submitdeal");
    }
  };

  const handleToggleCanvas = () => {
    setIsCanvasOpen(!isCanvasOpen);
  };

  //Filter Price Section

  const receiveChildData = (priceRange) => {
    setIsCanvasOpen(false)
    navigate(`?page=${1}`);
    setFilterFirstLoad(false);
    setPriceRange(priceRange);
    setFilStatus("price")
    setIsData(false);
    setLoading(true);
    setHomeFilter(false);
    let data = selectedCategories.join(",");

    setCurrentPage(1);

    if (selectedCategories.length !== 0) {
      setPfs(false);
      setDiscountRange(firstStoreDiscount);

      let price_filter_data = {
        store_id: data,
        page: 1,
        min_discount: "",
        max_discount: "",
        min_price: priceRange[0],
        max_price: priceRange[1],
        filter_type:"price"
      };

      searchFunction(price_filter_data);
    } else {
      let price_filter_data = {
        store_id: data,
        page: 1,
        min_discount: "",
        max_discount: "",
        min_price: priceRange[0],
        max_price: priceRange[1],
        filter_type:"price"
      };
      setDiscountRange(filteredDiscountRange);
      
      searchFunction(price_filter_data);
    }
  };


  //Discount Filter

  const receiveChildDataDiscount = (discountRange) => {
    setIsCanvasOpen(false)
    navigate(`?page=${1}`);
    setFilterFirstLoad(false);
    setDiscountRange(discountRange);
    setFilStatus("discount")
    setIsData(false);
    setLoading(true);
    setHomeFilter(false);
    let data = selectedCategories.join(",");

    setCurrentPage(1);

    if (selectedCategories.length !== 0) {
      setPfs(false);
      setPriceRange(firstStorePrice);
      let price_filter_data = {
        store_id: data,
        page: 1,
        min_discount: discountRange[0],
        max_discount: discountRange[1],
        min_price: "",
        max_price: "",
        filter_type:"discount"
      };

      searchFunction(price_filter_data);
    } else {
      let price_filter_data = {
        store_id: data,
        page: 1,
        min_discount: discountRange[0],
        max_discount: discountRange[1],
        min_price: "",
        max_price: "",
        filter_type:"discount"
      };
      setPriceRange(filteredPriceRange);
      searchFunction(price_filter_data);
    }
  };

  const handleSearchChange1 = (e) => {
    setSearchTerm1(e.target.value);
  };

  const handleCategoryChangeLocal = (selectedCategoriess) => {
    setIsCanvasOpen(false)
    navigate(`?page=${1}`);
    setSelectedCategories(selectedCategoriess);
    setFilStatus("")
    setCurrentPage(1);
    setIsData(false);
    setLoading(true);
    setHomeFilter(false);
    setFilterFirstLoad(false);
    if (selectedCategoriess.length !== 0) {
     
      let data = selectedCategoriess.join(",");
      // console.log("Selected Categories",data)
      setFilterFirstLoad(true);
      setPfs(true);
      let discount_data = {
        store_id: data,
        page: 1,
        min_discount: "",
        max_discount: "",
        min_price: "",
        max_price: "",
        filter_type:""
      };

      searchFunctionFilter(discount_data);
    } else {
      // console.log("Not selected categories")
      // setFilterFirstLoad(false)
      setFilStatus("")
      if(currentPage==1){
        firstData();
      }
      else{
        setHomeFilter(true);
      }
    
        
        //  firstData();
      
    }
  };
  return (
    <>
    <MetaTitleAndDescription />
      <div className="container">
        <h2 style={{ marginTop: "20px" }}>New Deals</h2>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>New Deals</Breadcrumb.Item>
        </Breadcrumb>

        {filterStatus && (
          <div
            style={{
              width: "auto",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <button
              onClick={handleToggleCanvas}
              className="toggle-canvas toggle-btn"
            >
              Feature Store →
            </button>
          </div>
        )}

        {loading ? (
          <div className="loading-spinner">
            <FontAwesomeIcon icon={faSpinner} spin fontSize="60px" />
          </div>
        ) : (<>
          <div className="store1">
            <div
              className={`product_grid1 ${isAsideOpen ? "grid1-4" : "grid1-4"}`}
            >
              {data && data.length > 0
                ? data.map((b,index) => <Deal item={b} />)
                : ""}
            </div>

            <SearchOffCanvas
              isCanvasOpen={isCanvasOpen}
              handleClose={() => setIsCanvasOpen(false)}
              store={filter}
              sendDataToParent={receiveChildData}
              sendDiscountData={receiveChildDataDiscount}
              handleSearchChange={handleSearchChange1}
              searchTerm={searchTerm1}
              selectedCategories={selectedCategories}
              handleCategoryChange={handleCategoryChangeLocal}
              priceRange={priceRange}
              filteredPriceRangeFirstTime={filteredPriceRange}
              filteredDiscountRangeFirstTime={filteredDiscountRange}
              discountRange={discountRange}
            />
          </div>
        
        {data && data.length > 0 && (
          <div className="page">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onNextClick={handleNextClick}
              onPrevClick={handlePrevClick}
              onPageClick={handlePageClick}
            />
          </div>
        )}
        {isData && data && data.length === 0 && (
          <p className="single_page_product">
            <Alert variant="primary">No Data Found</Alert>
          </p>
        )}
        {data.length > 0 && (
          <div className="btn_post">
            <button className="post_topic_btn" onClick={handleEducatorsPost}>
              POST NEW TOPIC
            </button>
          </div>
          )}
       </>
       
       )}
      </div>

      <Footer />
    </>
  );
};
