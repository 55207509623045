import React, { useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Button } from "react-bootstrap";

export const FilterDiscount = ({onFilter,store,selectedDiscountRange,discountRangeChanged,filteredDiscountRangeFirstTime}) => {

    const [discountRange, setDiscountRange] = useState([discountRangeChanged[0],discountRangeChanged[1]])

  const handleDiscountRangeChange = (values) => {
    setDiscountRange(values);
  };

  const handleFilterClick = () => {
    onFilter(discountRange);
  };

  const handleTooltip = (value) => {
    return `$${value.toFixed(2)}`;
  };


  return (
    <div style={{ margin: "20px" }}>
      <label>Filter By: Discount</label>
      <Slider
        range
        min={+filteredDiscountRangeFirstTime[0]}
        max={+filteredDiscountRangeFirstTime[1]}
        step={1}
        defaultValue={discountRange}
        onChange={handleDiscountRangeChange}
        tipFormatter={handleTooltip}
        allowCross={false}
      />
      <div style={{ display: "flex", justifyContent: "space-between", margin: "10px" }}>
        <span>${discountRange[0]==""?0:discountRange[0]}</span>
        <span>${discountRange[1]==""?0:discountRange[1]}</span>
      </div>
      <Button variant="primary" onClick={handleFilterClick}>
        Apply Filter
      </Button>
    </div>
  )
}