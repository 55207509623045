import React, { useEffect, useState } from "react";
import { Footer } from "./Footer";
import { Alert, Breadcrumb } from "react-bootstrap";
import { FeaturedStoreCarousel } from "./FeaturedStoreCarousel";
import axios from "axios";
import Cookies from "js-cookie";
import { bwcDealsApi } from "./Api";
import "./CommonProfile.css";
import { useNavigate, useParams } from "react-router-dom";
import { Deal } from "./Deal";
import Slider from "react-slick";
import { MetaTitleAndDescription } from "./MetaTitleAndDescription";

export const CommonProfile = () => {
  document.title="- BwcDeals"
  const [profile, setProfile] = useState([]);
  const [follow, setFollow] = useState([]);
  const [follower, setFollower] = useState([]);
  const [followerData, setFollowerData] = useState([]);
  const [followingData, setFollowingData] = useState([]);
  const [dealData, setDealData] = useState([]);
  const [status, setStatus] = useState(false);
  const csrftoken =  Cookies.get('csrftoken');
  const token = JSON.parse(
    Cookies.get("loginTokenBwcDeals") === undefined
      ? null
      : Cookies.get("loginTokenBwcDeals")
  );

  const { user_id } = useParams();
  const navigate = useNavigate();
// console.log("User id",user_id)
  useEffect(() => {
    axios
      .post(
        `${bwcDealsApi}/user_profile`,
        {
          loggedin_user_id: token && token.response.user_id,
          user_id: user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': csrftoken
          },
        }
      )

      .then((res) => {
        if (res.data.status == "true") {
          setStatus(false);
          setDealData(res.data.response.deal_data);
          setProfile(res.data.response.user_data);
          setFollow(res.data.response.followings_data);
          setFollower(res.data.response.followers_data);
          setFollowerData(res.data.response.followers_data);
          setFollowingData(res.data.response.followings_data);
        } else {
          setStatus(true);
          setDealData([]);
        }
      })
      .catch((error) => {});
  }, []);
  const numElements = Math.max(follow.length, follower.length);
  const minSlidesToShow = Math.min(numElements, 7);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: minSlidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: true,
    centerMode: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(numElements, 5),
          // slidesToScroll: 1,
          // infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: Math.min(numElements, 3),
          slidesToScroll: 1,
          // initialSlide: 2
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: Math.min(numElements, 2),
          // slidesToScroll: 1,
          // initialSlide: 2
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: Math.min(numElements, 1),
        },
      },
    ],
  };

  const handleFollow = (data) => {
    if (token) {
      axios
        .post(
          `${bwcDealsApi}/profile_follow`,
          {
            loggedin_user_id: token && token.response.user_id,
            user_id: user_id,
            status: data,
          },
          {
            headers: {
              "Content-Type": "application/json",
              'X-CSRFToken': csrftoken
            },
          }
        )

        .then((res) => {})
        .catch((error) => {});
      window.location.reload();
    } else {
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }
  };

  const handlefollowerprofile = (user_id) => {
    window.location.href=`/profile/${user_id}`;
  };

  const slideContents = follower.map((item) => (
    <div key={item.id} className="image_section">
      <img
        src={item.user_image}
        alt="book_image"
        onClick={() => handlefollowerprofile(item.user_id)}
        className="image_following"
        title={item.name}
      />
    </div>
  ));

  const slideContentsFollow = follow.map((item) => (
    <div key={item.id} className="image_section">
      <img
        src={item.user_image}
        alt="book_image"
        onClick={() => handlefollowerprofile(item.user_id)}
        className="image_following"
        title={item.name}
      />
    </div>
  ));
  return (
    <>
    <MetaTitleAndDescription />
    <div>
      <div className="container">
        <h2 style={{ marginTop: "20px" }}>Profile</h2>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Profile</Breadcrumb.Item>
        </Breadcrumb>

        {status ? (
          <p className="single_page_product">
            <Alert variant="primary">No Data Found</Alert>
          </p>
        ) : (
          <div className="profile_container">
            <div className="profile_user">
              <div className="user_section">
                <div className="user_info_left_part">
                  <div>
                    <img
                      src={profile.user_image}
                      alt="user"
                      className="user_image"
                    />
                  </div>
                  <div className="user_role">
                    <div className="user_detail">
                      <div className="name_type">
                        <h4 className="user_name_profile">{profile.user_name}</h4>
                        <p className="profile_role">{profile.user_type}</p>
                      </div>

                      <p>Joined Coupon on {profile.join_date}</p>
                      {(token && token.response.user_id==user_id) ?"": <div className="edit_profile_btn">
                        <button
                          className="edit_btn"
                          onClick={() =>
                            handleFollow(
                              profile.is_follow === 1 ? "UN FOLLOW" : "Follow"
                            )
                          }
                        >
                          {profile.is_follow === 1 ? "UN FOLLOW" : "FOLLOW"}
                        </button>
                      </div>}
                    </div>
                  </div>
                </div>
                <div className="user_info_right_part">
                  <div className="user_details_box">
                    <div className="box_detail">
                      <p className="post_detail">{profile.deal_count}</p>
                      <span>Deal Posted</span>
                    </div>
                    <div className="box_detail">
                      <p className="post_detail">{profile.coupon_count}</p>
                      <span>Coupon Posted</span>
                    </div>
                    <div className="box_detail">
                      <p className="post_detail">{profile.followers}</p>
                      <span>Followers</span>
                    </div>
                    <div className="box_detail">
                      <p className="post_detail">{profile.followings}</p>
                      <span>Following</span>
                    </div>
                  </div>
                </div>
              </div>

              {follower && follower.length > 0 && (
                <div className="following_section">
                  <h5 className="follow_text">Followers</h5>

                  <div className="carousel_card_div container">
                    <Slider {...settings} className="center-carousel">
                      {slideContents}
                    </Slider>
                  </div>
                </div>
              )}

              {follow && follow.length > 0 && (
                <div className="following_section">
                  <h5 className="follow_text">Followings</h5>

                  <div className="carousel_card_div container">
                    <Slider {...settings} className="center-carousel">
                      {slideContentsFollow}
                    </Slider>
                  </div>
                </div>
              )}

              <div className="educator_section">
                {dealData && dealData.length > 0 && (
                  <div className="user_profil_grid">
                    {dealData.map((item) => (
                      <Deal item={item} key={item.product_id} />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="featured_store">
              <FeaturedStoreCarousel />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
    </>
  );
};
