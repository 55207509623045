import React, { useEffect, useState } from "react";
// import { NavbarMain } from "./Navbar";
import { Footer } from "./Footer";
import { Breadcrumb } from "react-bootstrap";
import "./Profile.css";
import { FeaturedStoreCarousel } from "./FeaturedStoreCarousel";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { bwcDealsApi } from "./Api";
import { Deal } from "./Deal";
import Slider from "react-slick";
import { MetaTitleAndDescription } from "./MetaTitleAndDescription";

export const Profile = () => {
  document.title = "- BwcDeals";
  const [profile, setProfile] = useState([]);
  const [follow, setFollow] = useState([]);
  const [follower, setFollower] = useState([]);
  const [dealdata, setDealdata] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const token = JSON.parse(
    Cookies.get("loginTokenBwcDeals") === undefined
      ? null
      : Cookies.get("loginTokenBwcDeals")
  );
  const navigate = useNavigate();
  const csrftoken = Cookies.get("csrftoken");

  const fetchData = () => {
    axios
      .post(
        `${bwcDealsApi}/user_dashboard`,
        {
          user_id: token.response.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        }
      )

      .then((res) => {
        setProfile(res.data.response.user_data);
        setFollow(res.data.response.followings_data);
        setFollower(res.data.response.followers_data);
        setDealdata(res.data.response.deal_data);
        setSubscription(res.data.response.user_subscriptions);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = () => {
    navigate("/user/profile-edit");
  };

  const handlefollowerprofile = (user_id) => {
    navigate(`/profile/${user_id}`);
  };

  const handleCancel = (item) => {
    // console.log("Subscription Cancel ?: ",item)
    // axios
    //       .post(
    //         `${bwcDealsApi}/subscription_cancel`,
    //         {
    //           user_id: token.response.user_id,
    //           subscription_id:item.subscription_id,
    //           // loggedin_user_id: token.response.user_id,
    //         },
    //         {
    //           headers: {
    //             "Content-Type": "application/json",
    //           },
    //         }
    //       )
    //       .then((res) => {
    //         console.log("Subscription cancel data ", res);
    //       })
    //       .catch((error) => console.log(error));
  };

  const numElements = Math.max(follow.length, follower.length);
  const minSlidesToShow = Math.min(numElements, 7);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: minSlidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: true,
    centerMode: true,
    // fade: true,
    // vertical: false,
    // initialSlide:0,
    // rows:1,     //number of rows per slide in the slider, (enables grid mode)
    // slidesPerRow:1,   //number of slides to display in grid mode, this is useful with rows option
    // pauseOnHover: true,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(numElements, 5),
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: Math.min(numElements, 3),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: Math.min(numElements, 2),
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: Math.min(numElements, 1),
        },
      },
    ],
  };

  const slideContents = follower.map((item) => (
    <div key={item.id} className="image_section">
      <img
        src={item.user_image}
        alt="book_image"
        onClick={() => handlefollowerprofile(item.user_id)}
        className="image_following"
        title={item.name}
      />
    </div>
  ));

  const slideContentsFollow = follow.map((item) => (
    <div key={item.id} className="image_section">
      <img
        src={item.user_image}
        alt="book_image"
        onClick={() => handlefollowerprofile(item.user_id)}
        className="image_following"
        title={item.name}
      />
    </div>
  ));

  return (
    <>
     <MetaTitleAndDescription />
    <div>
      <div className="container">
        <h2 style={{ marginTop: "20px" }}>My Account</h2>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>My Account</Breadcrumb.Item>
        </Breadcrumb>

        <div className="profile_container">
          <div className="profile_user">
            {profile && (
              <div className="user_section">
                <div className="user_info_left_part">
                  <div>
                    <img
                      src={profile.user_image}
                      alt="user"
                      className="user_image"
                    />
                  </div>
                  <div className="user_role">
                    <div className="user_detail">
                      <div className="name_type">
                        <h4 className="user_name_profile">{profile.user_name}</h4>
                        <p className="profile_role">{profile.user_type}</p>
                      </div>

                      <p>Joined Coupon on {profile.join_date}</p>
                      <div className="edit_profile_btn">
                        <button className="edit_btn" onClick={handleEdit}>
                          EDIT PROFILE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="user_info_right_part">
                  <div className="user_details_box">
                    <div className="box_detail">
                      <p className="post_detail">{profile.deal_count}</p>
                      <span>Deal Posted</span>
                    </div>
                    <div className="box_detail">
                      <p className="post_detail">{profile.coupon_count}</p>
                      <span>Coupon Posted</span>
                    </div>
                    <div className="box_detail">
                      <p className="post_detail">{profile.followers}</p>
                      <span>Followers</span>
                    </div>
                    <div className="box_detail">
                      <p className="post_detail">{profile.followings}</p>
                      <span>Following</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {subscription && subscription.length > 0 && (
              <div className="subscription_section">
                <div>
                  <h1 className="subscription_title">My Subscription</h1>
                </div>
                <div>
                  <div className="border_class">
                    <span className="bold_title">Plan Name : </span>
                    {subscription[0].plan_name}
                  </div>
                  <div className="border_class">
                    <span className="bold_title">Plan Description : </span>
                    {subscription[0].plan_description}
                  </div>
                  <div className="border_class">
                    <span className="bold_title">Plan Price : </span>
                    {subscription[0].plan_price}
                  </div>
                  <div className="border_class">
                    <span className="bold_title">
                      Subscription Start Date :{" "}
                    </span>
                    {subscription[0].sub_start_date}
                  </div>
                  <div className="border_class">
                    <span className="bold_title">Subscription End Date : </span>
                    {subscription[0].sub_end_date}
                  </div>
                  <div className="border_class">
                    <span className="bold_title">Subscription Status :</span>{" "}
                    {subscription[0].sub_status}
                    {subscription[0].sub_status === "Active" && (
                      <button
                        className="button_cancel"
                        onClick={() => handleCancel(subscription[0])}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}

            {follower && follower.length > 0 && (
              <div className="following_section">
                <h5 className="follow_text">Followers</h5>

                <div className="carousel_card_div container">
                  <Slider {...settings} className="center-carousel">
                    {slideContents}
                  </Slider>
                </div>
              </div>
            )}

            {follow && follow.length > 0 && (
              <div className="following_section">
                <h5 className="follow_text">Followings</h5>

                <div className="carousel_card_div container">
                  <Slider {...settings} className="center-carousel">
                    {slideContentsFollow}
                  </Slider>
                </div>
              </div>
            )}

            <div className="educator_section">
              {dealdata && dealdata.length > 0 && (
                <div className="user_profil_grid">
                  {dealdata.map((item) => (
                    <Deal item={item} key={item.product_id} />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="featured_store">
            <FeaturedStoreCarousel />
          </div>
        </div>
      </div>
      <Footer />
    </div>

    </>
  );
};
