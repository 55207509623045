import React, { useEffect } from "react";
import "./UpperNav.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import axios from "axios";
import { bwcDealsApi } from "./Api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const UpperNav = () => {
  const [social, setSocial] = useState([]);
  const [general, setGeneral] = useState([]);

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`${bwcDealsApi}/social`)
      .then((res) => {
        setSocial(res.data.data);
      })
      .catch((error) => {
      });
  }, []);

  // console.log(social)
  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    axios
      .get(`${bwcDealsApi}/general_settings`)
      .then((res) => {
        setGeneral(res.data.footer_setting);
      })
      .catch((error) => {
      });
  };

  const handleSearchBox = () => {
    setShowSearchBox(true);
  };

  const hideSearchBox = () => {
    setShowSearchBox(false); // Hide the search box when the cross button is clicked
  };

  const handleSearch = () => {
    const encodedQuery = encodeURIComponent(query);
    navigate(`/search?query=${encodedQuery}`);
    window.location.reload();
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  
  
  return (
    <Navbar className="justify-content-center top_section" fluid>
      <Container>
        {showSearchBox && (
          <div className={`search-box ${showSearchBox ? "show" : ""}`}>
            <div className="search-content container">
              <div className="search-container">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Type anything to search"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
              </div>
              <button className="close-button" onClick={hideSearchBox}>
                ✕
              </button>
            </div>
          </div>
        )}

        <Navbar.Brand style={{ paddingTop: "0px" }}>
          <div className="image_box">
            {general[0]?.play_store_image && (
              <a href={general[0].Playstore_URL} target="_blank">
                <img
                  src={general[0].play_store_image}
                  alt="google_play"
                  className="social_media_image"
                />
              </a>
            )}

            {general[0]?.play_store_image && (
              <a href={general[0].App_Store_URL} target="_blank">
                <img
                  src={general[0].app_store_image}
                  alt="app_store"
                  className="social_media_image"
                />
              </a>
            )}

          </div>
        </Navbar.Brand>

        <Nav className="justify-content-center flex-grow-1">
          <div className="navbar_notificaton">
            <p style={{ marginBottom: "0px" }}>{general[0]?.Copyright_Text}</p>
          </div>
        </Nav>

        <div className="login_signup_box">
          {social &&
            social.length > 0 &&
            social.map((item) => {
              return (
                <a href={item.social_url} target="_blank" key={item.social_url}>
                  <i
                    className={item.social_image}
                    style={{ fontSize: "20px", color: "black" }}
                  ></i>
                </a>
              );
            })}

          <i
            className="fa fa-search"
            style={{ fontSize: "20px", color: "black" }}
            onClick={handleSearchBox}
          ></i>
        </div>
      </Container>
    </Navbar>
  );
};
