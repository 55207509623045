import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import "./CheckoutForm.css";

export const CheckoutForm = ({paymentIntentClientSecret}) => {
    const stripe = useStripe();
    const elements = useElements();
  
    const [errorMessage, setErrorMessage] = useState(null);
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      if (elements == null) {
        return;
      }
  
      // Trigger form validation and wallet collection
      const {error: submitError} = await elements.submit();
      if (submitError) {
        // Show error to your customer
        setErrorMessage(submitError.message);
        return;
      }
  
      // Create the PaymentIntent and obtain clientSecret from your server endpoint
      const res = await fetch('/create-intent', {
        method: 'POST',
      });
  
      const {client_secret: clientSecret} = await res.json();
  
      // const {error} = await stripe.confirmPayment({
      //   //`Elements` instance that was used to create the Payment Element
      //   elements,
      //   clientSecret,
      //   confirmParams: {
      //     return_url: 'https://example.com/order/123/complete',
      //   },
      // });
      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(PaymentElement),
        },
        confirmParams: {
              return_url: 'https://example.com/order/123/complete',
            },
      });
      if (error) {
        setErrorMessage(error.message);
      } else {
      }
    };
  
    return (
        <div style={{maxWidth:"500px",margin:"50px auto",border: '1px solid #E0E6EB',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',padding:"20px"}}>
            <form onSubmit={handleSubmit}>
                <PaymentElement />
                        <button type="submit" disabled={!stripe || !elements} style={{marginTop:"20px"}}>
                        Pay
                        </button>
               
                {errorMessage && <div>{errorMessage}</div>}
            </form>
      </div>
    );
  };