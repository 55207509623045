import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FeaturedStoreCarousel } from "./FeaturedStoreCarousel";
import { bwcDealsApi } from "./Api";
import "./CategoryOffcanvas.css";

const CategoryOffcanvas = ({
  isCanvasOpen,
  handleClose,
  handleSearchChange,
  searchTerm,
  selectedCategories,
  handleCategoryChange,
}) => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] =
    useState(selectedCategories);

  useEffect(() => {
    if (!isCanvasOpen) {
      setSelectedCheckboxes(selectedCategories);
    }
  }, [isCanvasOpen, selectedCategories]);

  useEffect(() => {
    axios
      .get(`${bwcDealsApi}/stores_list`)
      .then((response) => {
        setCategories(response.data.response.selected_data);
      })
      .catch((error) => {
        // console.error("Error fetching categories:", error);
      });
  }, []);

  useEffect(() => {
    const filtered = categories.filter(
      (category) =>
        category.title &&
        category.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCategories(filtered);
  }, [searchTerm, categories]);

  const handleToggleCategory = (storeId) => {

    let updatedSelectedCheckboxes;
    if (selectedCheckboxes.includes(storeId)) {
      updatedSelectedCheckboxes = selectedCheckboxes.filter(
        (id) => id !== storeId
      );
    } else {
      updatedSelectedCheckboxes = [...selectedCheckboxes, storeId];
    }
    setSelectedCheckboxes(updatedSelectedCheckboxes);
    handleCategoryChange(updatedSelectedCheckboxes);
  };

  return (
    <Offcanvas show={isCanvasOpen} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="carousel_card">
          <h4>Stores</h4>
          <div className="search-filter">
            <Form.Control
              type="text"
              placeholder="Search Stores"
              onChange={handleSearchChange}
              value={searchTerm}
            />
          </div>
          <div className="category-filter">
            {filteredCategories.map((category) => (
             

              <Form.Group controlId="formCheckbox">
                <Form.Check
                  type="checkbox"
                  label={category.title}
                  key={category.store_id}
                  checked={selectedCategories.includes(category.store_id)}
                  onChange={() => handleToggleCategory(category.store_id)}
                  id={`checkbox-${category.store_id}`} 
                />
              </Form.Group>
            ))}
          </div>
        </div>
        <div className="carousel_card">
          <FeaturedStoreCarousel />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CategoryOffcanvas;
