import React from "react";
import { Footer } from "../Components/Footer";
import { MetaTitleAndDescription } from "../Components/MetaTitleAndDescription";

export const PageNotFound = () => {
  return (
    <>
    <MetaTitleAndDescription />
    <div className="error-404-block text-center mt-5 page_not_found">
      <h3 className="error-heading">Oops!</h3>
      <h1 className="error-name">404</h1>
      <h3 className="error-subheading">Page Not Found</h3>
      <div className="home-btn">
        <a
          href={process.env.REACT_APP_FRONTEND_API}
          title="Home"
          className="btn btn-primary page_not_found_btn"
        >
          Go To Home
        </a>
      </div>
    </div>

    <Footer />
    </>
  );
};
