import React, { useEffect, useState } from "react";
import "./Product.css";
import { Alert, Breadcrumb } from "react-bootstrap";
import axios from "axios";
import { Pagination } from "../Components/Pagination";
import "./SinglePage.css";
import { Product } from "../Components/Product";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { NavbarMain } from "../Components/Navbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Footer } from "../Components/Footer";
import { Canvas } from "./OffCanvas";
import { bwcDealsApi } from "./Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import { MetaTitleAndDescription } from "./MetaTitleAndDescription";

export const SinglePage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const [currentPage, setCurrentPage] = useState((page === null || page === undefined || page === "")?1:page);
  const itemsPerPage = 15;
  const [isAsideOpen, setIsAsideOpen] = useState(false);
  const [isCanvasOpen, setIsCanvasOpen] = useState(false);
  const [visit, setVisit] = useState({});
  const [filter, setFilter] = useState({});
  const { slug } = useParams();
  const [priceRange, setPriceRange] = useState("");
  const [filteredPriceRange, setFilteredPriceRange] = useState([0, 0]);

  const [discountRange, setDiscountRange] = useState("");
  const [filteredDiscountRange, setFilteredDiscountRange] = useState([0, 0]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isData, setIsData] = useState(false);
  const [pagination, setPagination] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState([]);
  // const [firstLoad, setFirstLoad] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [homeFilter, setHomeFilter] = useState(true);
  const [filterPagination,setFilterPagination]= useState(false)
  const [filStatus,setFilStatus]= useState("")
  document.title="- BwcDeals"
  const navigate=useNavigate();
  const csrftoken =  Cookies.get('csrftoken');


  useEffect(() => {
    if (page === null || page === undefined || page === "") {
      setCurrentPage(1);
    } else {
      setCurrentPage(Number(page));
    }
  }, [page]);

  const firstData = () => {
    axios
      .post(`${bwcDealsApi}/category_page`, {
        slug: slug,
        type: "store",
        page: currentPage,
      },
      {
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      }
      )
      .then((r) => {
        setLoading(false);
        if(r.data.status=="false"){
          setIsData(true);
          setVisit({title:slug.charAt(0).toUpperCase() + slug.slice(1).replace(/-/g, " ")});
          setData([]);
          return;
        }
        setData(r.data.response.deal_data);
        setVisit(r.data.response.selected[0]);
        if (r.data.response.deal_data.length === 0) {
          setData([]);
          setIsData(true);
          setFilterStatus(false);
        } else {
          setIsData(false);
          setFilterStatus(true);
        }
        setFilteredPriceRange([
          r.data.response.price_filter[0].min_price,
          r.data.response.price_filter[0].max_price,
        ]);
        setPriceRange([
          r.data.response.price_filter[0].min_price,
          r.data.response.price_filter[0].max_price,
        ]);
        setDiscountRange([
          r.data.response.discount_filter[0].min_discount,
          r.data.response.discount_filter[0].max_discount,
        ]);
        setFilteredDiscountRange([
          r.data.response.discount_filter[0].min_discount,
          r.data.response.discount_filter[0].max_discount,
        ]);
        setFilter(r.data.response);
        setPagination(r.data.response.deal_data_pagination.total);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleToggleCanvas = () => {
    setIsCanvasOpen(!isCanvasOpen);
  };

  const searchFunction = (res) => {

    // console.log("Res",res)
   
    axios
      .post(`${bwcDealsApi}/filter`, res, {
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      })
      .then((res) => {
        // console.log("Response",res)
        if (res.data.status === "true") {
          setLoading(false);
          setIsCanvasOpen(false)
          setData(res.data.response.deal_data);

          setPagination(res.data.response.deal_data_pagination.total);
          if (
            res.data.response.deal_data &&
            res.data.response.deal_data.length > 0
          ) {
            // console.log(res.data.response.deal_data.length)
            setIsData(false);
          }
          else{
            // console.log(res.data.response.deal_data.length)
            setIsData(true);
          }
        } else {
          setData([]);
          setIsData(true);
        }
      })
      .catch((error) => {
        setLoading(false); 
      })
      .finally(() => {
        setLoading(false); 
      });
  };

  let dataGet = {
    store_id: visit.store_id,
    page: currentPage,
    min_discount: discountRange[0],
    max_discount: discountRange[1],
    min_price: priceRange[0],
    max_price: priceRange[1],
    filter_type:filStatus
    
  };
  
  useEffect(() => {
    if (homeFilter) {
      setLoading(true);
      firstData();
    } else {
      setLoading(true);
      if(filterPagination){
        searchFunction(dataGet);
      }
      
    }
  }, [currentPage]);

  const totalPages = Math.ceil(pagination / itemsPerPage);

  const handleNextClick = () => {
    const nextPage = currentPage + 1;
    navigate(`?page=${nextPage}`);
    setFilterPagination(true)
  };

  const handlePrevClick = () => {
    const prevPage = currentPage - 1;
    if (prevPage > 0) {
      navigate(`?page=${prevPage}`);
    }
    setFilterPagination(true)
    // setFirstLoad(true);
  };

  const handlePageClick = (pageNumber) => {
    navigate(`?page=${pageNumber}`);
    setFilterPagination(true)
    // setFirstLoad(true);
  };

 //Price Filter
  const receiveChildData = (priceRange) => {
    navigate(`?page=1`);
    setFilStatus("price")
    setPriceRange(priceRange);
    setDiscountRange(filteredDiscountRange);
    setFilterPagination(false)
    
    let price_filter_data = {
      store_id: visit.store_id,
      page: 1,
      min_discount: "",
      max_discount: "",
      min_price: priceRange[0],
      max_price: priceRange[1],
      filter_type:"price"
    };
    setLoading(true);
    setHomeFilter(false);
    searchFunction(price_filter_data);
    setCurrentPage(1);
  };

  //Discount Filter

  const receiveChildDataDiscount = (discountRange) => {
    navigate(`?page=1`);
    setFilStatus("discount")
    setDiscountRange(discountRange);
    setPriceRange(filteredPriceRange);
    setFilterPagination(false)
    
    let discount_data = {
      store_id: visit.store_id,
      page: 1,
      min_discount: discountRange[0],
      max_discount: discountRange[1],
      min_price: "",
      max_price: "",
      filter_type:"discount"
    };
    setLoading(true);
    setHomeFilter(false);
    searchFunction(discount_data);
    setCurrentPage(1);
  };

  return (
    <>
     <MetaTitleAndDescription />
      <div className="container">
        <h2 style={{ marginTop: "20px" }}>Exclusive Store Deals</h2>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/store">Stores</Breadcrumb.Item>
          <Breadcrumb.Item active>{visit.title}</Breadcrumb.Item>
        </Breadcrumb>
        {filterStatus && (
          <div
            style={{
              width: "auto",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          ><>
            <button
              onClick={handleToggleCanvas}
              className="toggle-canvas toggle-btn"
            >
              Filter →
            </button>
            </>
          </div>
        )}
        {loading ? (
          <div className="loading-spinner">
            <FontAwesomeIcon icon={faSpinner} spin fontSize="60px" />
          </div>
        ) : (
          <>
          <div className="store1">
            <div
              className={`product_grid1 ${isAsideOpen ? "grid1-4" : "grid1-4"}`}
            >
              {data && data.length > 0
                ? data.map((b) => <Product item={b} key={b.store_id} />)
                : ""}
            </div>

            <Canvas
              isCanvasOpen={isCanvasOpen}
              handleClose={() => setIsCanvasOpen(false)}
              visit={visit}
              store={filter}
              sendDataToParent={receiveChildData}
              sendDiscountData={receiveChildDataDiscount}
              selectedCategories={selectedCategories}
              priceRange={priceRange}
              filteredPriceRangeFirstTime={filteredPriceRange}
              filteredDiscountRangeFirstTime={filteredDiscountRange}
              discountRange={discountRange}
            />
          </div>
        

        {data && data.length > 0 && (
          <div className="page">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onNextClick={handleNextClick}
              onPrevClick={handlePrevClick}
              onPageClick={handlePageClick}
            />
          </div>
        )}
     </>
        )}

        
        {isData && data && data.length === 0 && (
          <p className="single_page_product">
            <Alert variant="primary">No Data Found</Alert>
          </p>
        )}
      </div>
      <Footer />
    </>
  );
};
