import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Navbar.css";
import { NavLink, useNavigate } from "react-router-dom";
import register_logo from "../Pictures/register.png";
import login_logo from "../Pictures/Vector.png";
import { UpperNav } from "./UpperNav";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { bwcDealsApi } from "./Api";
import axios from "axios";

export const NavbarMain = ({ loginStatus, onLogout }) => {
  // document.title="- BwcDeals"
  const [receivedData, setReceivedData] = useState({});
  const [general, setGeneral] = useState([]);
  const [expanded,setExpanded]= useState(false)
  const location = useLocation();
  const currentURL = location.pathname;
  const navigate = useNavigate();
  const token = JSON.parse(
    Cookies.get("loginTokenBwcDeals") === undefined
      ? null
      : Cookies.get("loginTokenBwcDeals")
  );
  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    axios
      .get(`${bwcDealsApi}/general_settings`)
      .then((res) => {
       
        setGeneral(res.data.general_setting);
      })
      .catch((error) => {
      });
  };


  useEffect(() => {
    if (token == null) {
    } else {
      setReceivedData(token && token.response.name);
    }
  }, []);

  const openLoginModal = () => {
    setExpanded(false)
    navigate("/login");
  };

  const openRegisterModal = () => {
    setExpanded(false)
    navigate("/register");
  };

  const active = {
    backgroundColor: "#353F66",
    borderRadius: "5px",
    color: "#FFF",
  };
  const notActive = {
    color: " #353F66",
  };

  const handleLogout = () => {
    Cookies.remove("loginTokenBwcDeals");
    setExpanded(false)
    setReceivedData("");
    onLogout();
    navigate("/");
  };

  const handleUserdetail = () => {
    setExpanded(false)
    navigate("/user/profile");
  };

  const handleNavClick=()=>{
    setExpanded(false)
  }

  const handleMouseDown = (event) => {
    if (event.button === 1) {
      window.open(`/login`, '_blank');
    }
  };

  const handleMyAccount =(event)=>{
    if (event.button === 1) {
      window.open(`/user/profile`, '_blank');
    }
  }
  const handleMouseRegister =(event)=>{
    if (event.button === 1) {
      window.open(`/register`, '_blank');
      // openLoginModal();
    }
  }
  const handleMouseLogout =(event)=>{
    if (event.button === 1) {
      window.open(`/logout`, '_blank');
      // openLoginModal();
    }
  }



  
  return (
    <div>
      <UpperNav />
      <div className="navbar_notificaton_div container">
        <p style={{ marginBottom: "0px" }}>
          BwcDeals may make commissions from Brands, Deals, or Stores. Post may
          contain affiliate links.
        </p>
      </div>
      <Navbar
        expand="lg"
        className="bg-body-tertiary justify-content-center"
        style={{ padding: "0px" }}
        fluid
        expanded={expanded}
      >
        <Container>
          <Navbar.Brand href="/" style={{ padding: "0" }}>
            {general[0]?.Website_Logo && (
              <img
                src={general[0].Website_Logo}
                alt="Company_logo"
                className="company_logo"
              />
            )}
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav"  onClick={()=>setExpanded(!expanded)}/>
          <Navbar.Collapse id="basic-navbar-nav align_navbar" style={{width:"-webkit-fill-available"}}>
          <Nav className="justify-content-center flex-grow-1 options" onClick={handleNavClick}>
              <NavLink
                to="/"
                className="me-autod"
                style={({ isActive }) => (isActive ? active : notActive)}
              >
                Home
              </NavLink>
              <NavLink
                to="/store"
                className="me-autod"
                style={({ isActive }) => (isActive ? active : notActive)}
              >
                Stores
              </NavLink>
              <NavLink
                to="/category"
                className="me-autod"
                style={({ isActive }) => (isActive ? active : notActive)}
              >
                Categories
              </NavLink>
              <NavLink
                to="/submitdeal"
                className="me-autod"
                style={({ isActive }) => (isActive ? active : notActive)}
              >
                Submit Deals
              </NavLink>
              <NavLink
                to="/educator/mpGRQ/clear-the-lists-verified-walmart-registry"
                className="me-autod"
                style={({ isActive }) => (isActive ? active : notActive)}
              >
                Educators
              </NavLink>
              <NavLink
                to="/contact"
                className="me-autod"
                style={({ isActive }) => (isActive ? active : notActive)}
              >
                Contact Us
              </NavLink>
            </Nav>


            {loginStatus === false ? (
              <div className="login_signup_box">
                <button onClick={openLoginModal} className="button_login" onMouseDown={handleMouseDown}>
                  <img src={login_logo} alt="login_logo" /> <b> Login</b>
                </button>

                <button onClick={openRegisterModal} className="button_register" onMouseDown={handleMouseRegister}>
                  <img src={register_logo} alt="register_logo" />{" "}
                  <b> Register</b>
                </button>
              </div>
            ) : (
              <div className="login_signup_box">
                <button className="button_login" onClick={handleUserdetail} onMouseDown={handleMyAccount}>
                  <b> My Account</b>
                </button>

                <button onClick={handleLogout} className="button_register" onMouseDown={handleMouseLogout}>
                  <b> Logout</b>
                </button>
                {/* <GoogleLogOut  /> */}
              </div>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
