import React, { useEffect, useState } from "react";
import { FeaturedStoreCarousel } from "./FeaturedStoreCarousel";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { FilterPrice } from "./FilterPrice";
import { FilterDiscount } from "./FilterDiscount";
import axios from "axios";
import { bwcDealsApi } from "./Api";

export const SearchOffCanvas = ({
  isCanvasOpen,
  handleClose,
  store,
  sendDataToParent,
  sendDiscountData,
  handleSearchChange,
  searchTerm,
  selectedCategories,
  handleCategoryChange,
  priceRange,
  filteredPriceRangeFirstTime,
  filteredDiscountRangeFirstTime,
  discountRange,
}) => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] =
    useState(selectedCategories);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const [filteredPriceRange, setFilteredPriceRange] = useState([
    filteredPriceRangeFirstTime[0],
    filteredPriceRangeFirstTime[1],
  ]);
  const [filteredDiscountRange, setFilteredDiscountRange] = useState([
    filteredDiscountRangeFirstTime[0],
    filteredDiscountRangeFirstTime[1],
  ]);



  useEffect(() => {
    axios
      .get(`${bwcDealsApi}/stores_list`)
      .then((response) => {
        setCategories(response.data.response.selected_data);
      })
      .catch((error) => {
        // console.error("Error fetching categories:", error);
      });
  }, []);
  useEffect(() => {
    if (store && store.price_filter) {
      setFilteredPriceRange([
        store.price_filter[0].min_price,
        store.price_filter[0].max_price,
      ]);
    }
    if (store && store.discount_filter) {
      setFilteredDiscountRange([
        store.discount_filter[0].min_discount,
        store.discount_filter[0].max_discount,
      ]);
    }
  }, [store]);

  const handleFilter = (priceRange) => {

    setFilteredPriceRange(priceRange);
    sendDataToParent(priceRange);
  };

  const handledisFilter = (discountRange) => {

    setFilteredDiscountRange(discountRange);

    sendDiscountData(discountRange);
  };



  useEffect(() => {
    const filtered = categories.filter(
      (category) =>
        category.title &&
        category.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCategories(filtered);
  }, [searchTerm, categories]);

  const handleToggleCategory = (storeId) => {

    let updatedSelectedCheckboxes;
    if (selectedCheckboxes.includes(storeId)) {
      updatedSelectedCheckboxes = selectedCheckboxes.filter(
        (id) => id !== storeId
      );
    } else {
      updatedSelectedCheckboxes = [...selectedCheckboxes, storeId];
    }
    setSelectedCheckboxes(updatedSelectedCheckboxes);
    handleCategoryChange(updatedSelectedCheckboxes);
  };
  return (
    <Offcanvas
      show={isCanvasOpen && !filtersApplied}
      onHide={handleClose}
      placement="end"
      className="canvas"
    >
      <Offcanvas.Header closeButton>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="carousel_card">
          <FilterPrice
            onFilter={handleFilter}
            store={store}
            selectedPriceRange={filteredPriceRange}
            priceRangeChanged={priceRange}
            filteredPriceRangeFirstTime={filteredPriceRangeFirstTime}
          />
        </div>
        <div className="carousel_card">
          <FilterDiscount
            onFilter={handledisFilter}
            store={store}
            selectedDiscountRange={filteredDiscountRange}
            filteredDiscountRangeFirstTime={filteredDiscountRangeFirstTime}
            discountRangeChanged={discountRange}
          />
        </div>
        {/* (filteredCategories && filteredCategories.length>0) &&  */}
        {<div className="carousel_card">
          <h4>Stores</h4>
          <div className="search-filter">
            <Form.Control
              type="text"
              placeholder="Search Stores"
              onChange={handleSearchChange}
              value={searchTerm}
            />
          </div>
          <div className="category-filter">
            {filteredCategories.map((category) => (
              <Form.Group controlId="formCheckbox"  key={category.store_id}>
                <Form.Check
                  type="checkbox"
                  label={category.title}
                 
                  checked={selectedCategories.includes(category.store_id)}
                  onChange={() => handleToggleCategory(category.store_id)}
                  id={`checkbox-${category.store_id}`} 
                />
              </Form.Group>
            ))}
          </div>
        </div>}
      </Offcanvas.Body>
    </Offcanvas>
  );
};
