import React from "react";
import Slider from "react-slick";
import "./Carousel.css";
import book_image from "../Pictures/book_image.png";

export const Carousel = ({data}) => {

  const handleClick=(link)=>{
    // console.log(link)
    window.open(link, "_blank")
  }
  const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 3000,
      cssEase: "linear",
      arrows:false,
      // centerMode:true,
      // pauseOnHover: true,
      // initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            
          }
        },
        {
          breakpoint: 760,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            // initialSlide: 2
          }
        },
        
      ]
      
  };
  const slideContents = data.map((item) => (
   
    <div key={item.id}>
      <div className="image-container" onClick={()=>handleClick(item.amazon_wishlist)}>
          <img src={book_image} alt="book_image"/>
          <div  className="text-overlay">
          <p>{item.first_name}{" "}{item.last_name}</p>
          <p>{item.school_location}</p>
          </div>
      </div>
      
     


    </div>
  ));

  return <Slider {...settings} className="center-carousel">{slideContents}</Slider>;
};
				


		

	
