import React, { useState } from "react";
// import { NavbarMain } from "../Components/Navbar";
import { FeaturedStoreCarousel } from "../Components/FeaturedStoreCarousel";
import { Footer } from "../Components/Footer";
import "./ContactUs.css";
import { Alert, Breadcrumb } from "react-bootstrap";
import axios from "axios";
import { bwcDealsApi } from "../Components/Api";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { MetaTitleAndDescription } from "../Components/MetaTitleAndDescription";

export const ContactUs = () => {
  const initialValues={name:"",category:"inquiry",subject:"",email:"",mobile:"",website:"",message:""}
  const [formValues,setFormValues]= useState(initialValues);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const csrftoken =  Cookies.get('csrftoken');
  document.title="- BwcDeals"
  const handleChange =(e)=>{
 
    const {name,value}= e.target;
    
    setFormValues({...formValues,[name]:value})
}

  const handleSubmit = async(e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${bwcDealsApi}/contact_us`, formValues,{
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      });
    if (response.status ===200) {
       window.scrollTo({
        top: 0,
        behavior: 'smooth', 
      });
      setSuccess(response.data.message);
      setError(null);

      
        setTimeout(()=>{
         
          setSuccess(null);
           setFormValues(initialValues)
        },3000)
          
    }
   
  } catch (err) {  
    setError(err.response.data.message);
    window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
    setSuccess(null);
    setTimeout(()=>{
         
      setError(null);
    },3000)
 
  }
  };

  return (
    <>
    <MetaTitleAndDescription />
    <div>

      <div className="container">
        <div className="educator_div_section">
        <div>
        <h2 style={{ marginTop: "20px" }}>Contact Us</h2>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      
      </div>

        <div className="coupon-page-block categories-page contact-page">
          <div className="coupon-dtl-outer">
            <div className="row">
              <div className="col-lg-9 col-md-8">
                <div className="submit-deal-main-block">
                  
                {success==="Contact information submitted successfully." && <Alert variant="success" style={{fontSize:"20px"}}>{success}</Alert>}
                    {success==="All fields are required." && <Alert variant="danger" style={{fontSize:"20px"}}>{success}</Alert>}
                  <form
                    id="submit-deal"
                    className="submit-deal-form contact-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-group">
                      <label for="name">Name<span className="star_color">*</span></label>

                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="form_control"
                        placeholder="Enter Your Full Name"
                        required
                        value={formValues.name}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-group">
                      <label for="store">Choose Categories</label>

                      <select
                        className="form_control"
                        name="category"
                        id="store"
                        value={formValues.category}
                              onChange={handleChange}
                      >
                        <option value="inquiry">Inquiry</option>

                        <option value="feedback">Feedback</option>

                        <option value="suggestions">Suggestions</option>

                        <option value="coupons-issue">Coupons Issues</option>

                        <option value="deal-issue">Deal Issues</option>

                        <option value="forums-issue">Forums Issues</option>

                        <option value="report-user">Report User</option>

                        <option value="dmca">DMCA</option>

                        <option value="others">Others</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label for="subject">Subject<span className="star_color">*</span></label>

                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        className="form_control"
                        placeholder="Enter Subject"
                        required
                        value={formValues.subject}
                              onChange={handleChange}
                      />
                    </div>

                    <div className="form-group">
                      <label for="email">Email Address<span className="star_color">*</span></label>

                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form_control"
                        placeholder="Enter Your Email Address"
                        required
                        value={formValues.email}
                              onChange={handleChange}
                      />
                    </div>

                    <div className="form-group">
                      <label for="mobile">Mobile Number<span className="star_color">*</span></label>

                      <input
                        type="number"
                        name="mobile"
                        id="mobile"
                        className="form_control"
                        placeholder="Enter Mobile Number"
                        required
                        value={formValues.mobile}
                              onChange={handleChange}
                      />
                    </div>

                    <div class="form-group">
                      <label for="website">Website</label>

                      <input
                        type="url"
                        id="website"
                        name="website"
                        class="form_control"
                        placeholder="Enter Your Website"
                        // required
                        value={formValues.website}
                              onChange={handleChange}
                      />
                    </div>

                    <div class="form-group">
                      <label for="message">Message</label>

                      <textarea
                        class="form-control"
                        name="message"
                        id="message"
                        placeholder="Enter Your Message"
                        // required
                        value={formValues.message}
                              onChange={handleChange}
                        rows="5"
                      ></textarea>
                    </div>

                    <div class="form-group">
                      <div class="submit-deal-btn">
                        <button type="submit" class="btn_primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="featured_carousel_small_screen">
                    <FeaturedStoreCarousel />
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4">
                <div class="coupon-sidebar">
                  <div class="page-sidebar-widget popular-store-widget">
                    <div className="featured_carousel_long_div">
                      <FeaturedStoreCarousel />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>

    </>
  );
};
