import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Footer } from "./Footer";
import { useEffect } from "react";
import axios from "axios";
import { bwcDealsApi } from "./Api";
import { useState } from "react";
import { Alert, Breadcrumb } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Pagination } from "./Pagination";
import "./SearchPage.css";
import { Product } from "./Product";
import { SearchOffCanvas } from "./SearchOffCanvas";
import Cookies from "js-cookie";
import { MetaTitleAndDescription } from "./MetaTitleAndDescription";

export const SearchPage = () => {
  document.title="- BwcDeals"
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const [currentPage, setCurrentPage] = useState((page === null || page === undefined || page === "")?1:Number(page));
  const itemsPerPage = 15;
  const [pagination, setPagination] = useState(0);
  const [isAsideOpen, setIsAsideOpen] = useState(false);
  const [isCanvasOpen, setIsCanvasOpen] = useState(false);
  const [filter, setFilter] = useState({});
  const [priceRange, setPriceRange] = useState([]);
  const [filteredPriceRange, setFilteredPriceRange] = useState([0, 0]);
  const [isData, setIsData] = useState(false);

  const [discountRange, setDiscountRange] = useState([]);
  const [filteredDiscountRange, setFilteredDiscountRange] = useState([0, 0]);

  const [searchTerm1, setSearchTerm1] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [filterFirstLoad, setFilterFirstLoad] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);

  const [homeFilter, setHomeFilter] = useState(true);

  const [pfs, setPfs] = useState(true);

  const [firstStorePrice, setFirstStorePrice] = useState([0, 0]);
  const [firstStoreDiscount, setFirstStoreDiscount] = useState([0, 0]);
  const [filStatus,setFilStatus]= useState("")
  const navigate= useNavigate();
  // const queryParams = new URLSearchParams(location.search);

  const encodedQuery = queryParams.get("query");
  const str = encodedQuery ? decodeURIComponent(encodedQuery) : "";

  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  };
  const csrftoken =  Cookies.get('csrftoken');

  const query = capitalizeFirstLetter(str);


  useEffect(() => {
    if (page === null || page === undefined || page === "") {
      setCurrentPage(1);
    } else {
      setCurrentPage(Number(page));
    }
  }, [page]);
  const firstData = () => {
    setHomeFilter(true)
    let obj={
      search_title: str,
      store_id: "",
      page: currentPage,
      min_discount: "",
      max_discount: "",
      min_price: "",
      max_price: "",
    }
    // console.log("Obj",obj)
    axios
      .post(
        `${bwcDealsApi}/filter`,
        {
          search_title: str,
          store_id: "",
          page: currentPage,
          min_discount: "",
          max_discount: "",
          min_price: "",
          max_price: "",
        },
        {
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      }
      )
      .then((r) => {
        // console.log("Home Data",r)
        if (r.data.status === "true") {
          setData(r.data.response.deal_data);
          if (r.data.response.deal_data.length === 0) {
            setIsData(true);
          }
          setFilterStatus(true);
          setFilteredPriceRange([
            r.data.response.price_filter[0].min_price,
            r.data.response.price_filter[0].max_price,
          ]);
          setPriceRange([
            r.data.response.price_filter[0].min_price,
            r.data.response.price_filter[0].max_price,
          ]);
          setDiscountRange([
            r.data.response.discount_filter[0].min_discount,
            r.data.response.discount_filter[0].max_discount,
          ]);
          setFilteredDiscountRange([
            r.data.response.discount_filter[0].min_discount,
            r.data.response.discount_filter[0].max_discount,
          ]);
          setFilter(r.data.response);
          setPagination(r.data.response.deal_data_pagination.total);
        } else {
          setData([]);
          setIsData(true);
          setFilterStatus(false);
        }
      })
      .catch((error) => {
        // console.log("Home error",error)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchFunction = (rest) => {
    
    setFilterFirstLoad(true)
    // console.log("searchFunction",rest)
    axios
      .post(`${bwcDealsApi}/filter`, rest, {
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      })
      .then((res) => {
        // console.log("First filter",res.data)
        
        if (res.data.status === "true") {
          setLoading(false);
          setData(res.data.response.deal_data);
          setPagination(res.data.response.deal_data_pagination.total);
          if (
            res.data.response.deal_data &&
            res.data.response.deal_data.length > 0
          ) {
            // console.log(res.data.response.deal_data.length)
            setIsData(false);
          }
          else{
            // console.log(res.data.response.deal_data.length)
            setIsData(true);
          }

         
        } else {
          
          setIsData(true);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const searchFunctionFilter = (rest) => {
    setFilterFirstLoad(true)
    // console.log("Rest",rest)

    axios
      .post(`${bwcDealsApi}/filter`, rest, {
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      })
      .then((res) => {
        // console.log("searchFunctionFilter",res)
       
        if (res.data.status === "true") {
          setLoading(false);
          
          setPagination(res.data.response.deal_data_pagination.total);

          setPriceRange([
            res.data.response.price_filter[0].min_price,
            res.data.response.price_filter[0].max_price,
          ]);
          setDiscountRange([
            res.data.response.discount_filter[0].min_discount,
            res.data.response.discount_filter[0].max_discount,
          ]);
          setFirstStorePrice([
            res.data.response.price_filter[0].min_price,
            res.data.response.price_filter[0].max_price,
          ]);
          setFirstStoreDiscount([
            res.data.response.discount_filter[0].min_discount,
            res.data.response.discount_filter[0].max_discount,
          ]);
  
          setFilteredPriceRange([
            res.data.response.price_filter[0].min_price,
            res.data.response.price_filter[0].max_price,
          ]);
          setFilteredDiscountRange([
            res.data.response.discount_filter[0].min_discount,
            res.data.response.discount_filter[0].max_discount,
          ]);
          if (
            res.data.response.deal_data &&
            res.data.response.deal_data.length > 0
          ) {
            setIsData(false);
            setData(res.data.response.deal_data);
            
          } else {
            setData([]);
            setIsData(true);
          }
          
        } else {
          setData([]);
          setIsData(true);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };



  useEffect(() => {
    if (homeFilter) {
      // console.log("Home filter")
      firstData();
      setLoading(true);
      setFilterFirstLoad(false);
      
    } else {
      setLoading(true);
      if (filterFirstLoad) {
        setHomeFilter(false);
        // if (selectedCategories.length !== 0) {
          let dataStore = selectedCategories.join(",");
          let dataGet = {
            search_title: str,
            store_id: dataStore,
            page: currentPage,
            min_discount: discountRange[0],
            max_discount: discountRange[1],
            min_price: priceRange[0],
            max_price: priceRange[1],
            filter_type:filStatus
          };
          searchFunction(dataGet);
        // } 
      }
    }
  }, [currentPage]);

  const totalPages = Math.ceil(pagination / itemsPerPage);

  const handleNextClick = () => {
    setFirstLoad(true);
    const nextPage = currentPage + 1;
    navigate(`?query=${str}&page=${nextPage}`);
  };

  const handlePrevClick = () => {
    setFirstLoad(true);
    const prevPage = currentPage - 1;
    if (prevPage > 0) {
      navigate(`?query=${str}&page=${prevPage}`);
    }
  };

  const handlePageClick = (pageNumber) => {
    setFirstLoad(true);
    navigate(`?query=${str}&page=${pageNumber}`);
  };

  const handleToggleCanvas = () => {
    setIsCanvasOpen(!isCanvasOpen);
  };

  //Filter Price Section

  const receiveChildData = (priceRange) => {
    setIsCanvasOpen(false)
    navigate(`?query=${str}&page=${1}`);
    setFilterFirstLoad(false);
    setPriceRange(priceRange);
    setFilStatus("price")
    setIsData(false);
    setLoading(true);
    setHomeFilter(false);
    let data = selectedCategories.join(",");

    setCurrentPage(1);

    if (selectedCategories.length !== 0) {
      setPfs(false);
      setDiscountRange(firstStoreDiscount);

      let price_filter_data = {
        search_title: str,
        store_id: data,
        page: 1,
        min_discount: "",
        max_discount: "",
        min_price: priceRange[0],
        max_price: priceRange[1],
        filter_type:"price"
      };

      searchFunction(price_filter_data);
    } else {
      let price_filter_data = {
        search_title: str,
        store_id: data,
        page: 1,
        min_discount: "",
        max_discount: "",
        min_price: priceRange[0],
        max_price: priceRange[1],
        filter_type:"price"
      };
      setDiscountRange(filteredDiscountRange);
      
      searchFunction(price_filter_data);
    }
  };


  //Discount Filter

  const receiveChildDataDiscount = (discountRange) => {
    setIsCanvasOpen(false)
    navigate(`?query=${str}&page=${1}`);
    setFilterFirstLoad(false);
    setDiscountRange(discountRange);
    setFilStatus("discount")
    setIsData(false);
    setLoading(true);
    setHomeFilter(false);
    let data = selectedCategories.join(",");

    setCurrentPage(1);

    if (selectedCategories.length !== 0) {
      setPfs(false);
      setPriceRange(firstStorePrice);
      let price_filter_data = {
        search_title: str,
        store_id: data,
        page: 1,
        min_discount: discountRange[0],
        max_discount: discountRange[1],
        min_price: "",
        max_price: "",
        filter_type:"discount"
      };

      searchFunction(price_filter_data);
    } else {
      let price_filter_data = {
        search_title: str,
        store_id: data,
        page: 1,
        min_discount: discountRange[0],
        max_discount: discountRange[1],
        min_price: "",
        max_price: "",
        filter_type:"discount"
      };
      setPriceRange(filteredPriceRange);
      searchFunction(price_filter_data);
    }
  };

  const handleSearchChange1 = (e) => {
    setSearchTerm1(e.target.value);
  };

  const handleCategoryChangeLocal = (selectedCategoriess) => {
    setIsCanvasOpen(false)
    navigate(`?query=${str}&page=${1}`);
   
    setSelectedCategories(selectedCategoriess);
    setFilStatus("")
    setCurrentPage(1);
    setIsData(false);
    setLoading(true);
    setHomeFilter(false);
    setFilterFirstLoad(false);
    if (selectedCategoriess.length !== 0) {
      let data = selectedCategoriess.join(",");
      // console.log("Selected Categories",data)
      setFilterFirstLoad(true);
      setPfs(true);
      let discount_data = {
        search_title: str,
        store_id: data,
        page: 1,
        min_discount: "",
        max_discount: "",
        min_price: "",
        max_price: "",
        filter_type:""
      };

      searchFunctionFilter(discount_data);
    } else {
      // console.log("Not selected categories")
      // setFilterFirstLoad(false)
      setFilStatus("")
      if(currentPage==1){
        firstData();
      }
      else{
        setHomeFilter(true);
      }
    
        
        //  firstData();
      
    }
  };

  return (
    <>
     <MetaTitleAndDescription />
      <div className="container">
        <h2 style={{ marginTop: "20px" }}>{query} Related Deals & Coupon</h2>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="">Search</Breadcrumb.Item>
        </Breadcrumb>
        {filterStatus && (
          <div className="row_reverse">

          <>
            <button
              onClick={handleToggleCanvas}
              className="toggle-canvas toggle-btn"
            >
              Feature Store →
            </button>

            </>
          </div>
        )}
        {loading ? (
          <div className="loading-spinner">
            <FontAwesomeIcon icon={faSpinner} spin fontSize="60px" />
          </div>
        ) : (<>
          <div className="store1">
            <div
              className={`product_grid1 ${isAsideOpen ? "grid1-4" : "grid1-4"}`}
            >
              {data && data.length > 0
                ? data.map((b) => <Product item={b} key={b.store_id} />)
                : ""}
            </div>

            <SearchOffCanvas
              isCanvasOpen={isCanvasOpen}
              handleClose={() => setIsCanvasOpen(false)}
              store={filter}
              sendDataToParent={receiveChildData}
              sendDiscountData={receiveChildDataDiscount}
              handleSearchChange={handleSearchChange1}
              searchTerm={searchTerm1}
              selectedCategories={selectedCategories}
              handleCategoryChange={handleCategoryChangeLocal}
              priceRange={priceRange}
              filteredPriceRangeFirstTime={filteredPriceRange}
              filteredDiscountRangeFirstTime={filteredDiscountRange}
              discountRange={discountRange}
            />
          </div>
        
        {isData && (data && data.length === 0) && (
          <div className="store_no_data_found">
            <Alert variant="primary">No Data Found</Alert>
          </div>
        )}
        {(totalPages > 1) && (
          <div className="page">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onNextClick={handleNextClick}
              onPrevClick={handlePrevClick}
              onPageClick={handlePageClick}
            />
          </div>
        )}
      </>
        )}
      </div>
      <Footer />
    </>
  );
};
