import React, { useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faGoogle } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { Footer } from "./Footer";
import Cookies from "js-cookie";
import { bwcDealsApi } from "./Api";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "./firebase-config";
import { FacebookAuthProvider } from "firebase/auth";
import { MetaTitleAndDescription } from "./MetaTitleAndDescription";

export const Login = ({ onLogin }) => {
  document.title = "- BwcDeals";
  const [isnameFocused, setIsNameFocused] = useState(false);
  const [ispswdFocused, setIsPswdFocused] = useState(false);
  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState([]);
  const [isFacebookLoginLoaded, setIsFacebookLoginLoaded] = useState(false);
  const csrftoken = Cookies.get("csrftoken");
  const googleProvider = new GoogleAuthProvider();

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      // console.log(result);
      const formData = {
        provider: "google",
        provider_id: result.user.providerData[0].uid,
        name: result.user.providerData[0].displayName,
        email: result.user.providerData[0].email,
        profile_img: result.user.providerData[0].photoURL,
      };
      // console.log("Form data",formData)
      const response = await axios.post(
        `${bwcDealsApi}/social_signin`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        }
      );

      // console.log("Response form Data", response);
      onLogin();
      Cookies.set("loginTokenBwcDeals", JSON.stringify(response.data.data));
      setError(null);
      setSuccess("Successfully Login");

      setTimeout(() => {
        setSuccess(null);
        navigate("/");
      }, 3000);
    } catch (error) {
      // console.log(error);
      setError("Something went wrong in social login. please try again later.");
      setSuccess(null);
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  const facebookProvider = new FacebookAuthProvider();

  const signInWithFacebook = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      // console.log(result.user.providerData[0]);

      const formData = {
        provider: "facebook",
        provider_id: result.user.providerData[0].uid,
        name: result.user.providerData[0].displayName,
        email: result.user.providerData[0].email,
        profile_img: result.user.providerData[0].photoURL,
      };
      // console.log("Form data",formData)
      const response = await axios.post(
        `${bwcDealsApi}/social_signin`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        }
      );

      // console.log("Response form Facebook Data", response);
      onLogin();
      Cookies.set("loginTokenBwcDeals", JSON.stringify(response.data.data));
      setError(null);
      setSuccess("Successfully Login");

      setTimeout(() => {
        setSuccess(null);
        navigate("/");
      }, 3000);
    } catch (error) {
      // console.log(error);
      setError("Something went wrong in social login. please try again later.");
      setSuccess(null);
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  const openRegister = () => {
    navigate("/register");
  };

  const handleForgotPassword = () => {
    navigate("/forgotpassword");
  };

  const handleNameFocus = () => {
    setIsNameFocused(true);
  };

  const handleNameBlur = () => {
    setIsNameFocused(false);
  };

  const handlePswdFocus = () => {
    setIsPswdFocused(true);
  };

  const handlePswdBlur = () => {
    setIsPswdFocused(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (formValues.email !== "" && formValues.password !== "") {
      try {
        const response = await axios.post(`${bwcDealsApi}/signin`, formValues, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        });
        // window.location.href="admin/dashboard";
        // console.log("Response Login", response);
        // window.location.href="https://python.hexagoninfosoft.in/admin/dashboard";
        if (response.data.message === "Successfully Login") {
          onLogin();
          Cookies.set("loginTokenBwcDeals", JSON.stringify(response.data));
          setError(null);
          setSuccess(response.data.message);

          setTimeout(() => {
            setSuccess(null);
           
            // if(response.data.admin){
            //   Cookies.set("sessionid", JSON.stringify(response.data.session_id));
            //   window.location.href=`${process.env.REACT_APP_FRONTEND_API}/${response.data.redirect_url}`;
            // }else{
              navigate("/");
            // }
            
          }, 3000);
        } else if (response.data.status === "False") {
          setError(response.data.message);
          setTimeout(() => {
            setError(null);
          }, 3000);
        }
      } catch (err) {
        // console.log(err)
        setError(err.response.data.message);
        setSuccess(null);
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };

  const validate = (values) => {
    const errors = {};

    if (values.email === "") {
      errors.email = "Email is required !";
    }
    if (values.password === "") {
      errors.password = "Password is required !";
    }

    return errors;
  };

  return (
    <>
    <MetaTitleAndDescription />
      <div className="container-fluid">
        <div className="row justify-content-md-center">
          <div className="col-xxl-4 col-xl-6 col-md-8 col-lg-7 col-sm-11 loginAndForm">
            <div class="container mt-4">
              <div class="login-container">
                {success && (
                  <Alert variant="success" style={{ fontSize: "20px" }}>
                    {success}
                  </Alert>
                )}
                {error && (
                  <Alert variant="danger" style={{ fontSize: "20px" }}>
                    {error}
                  </Alert>
                )}

                <form onSubmit={handleSubmit}>
                  <Form>
                    <p className="login_text">Login</p>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <div className="mb-3">
                        <Form.Control
                          type="email"
                          className="input_box"
                          placeholder="Email"
                          onFocus={handleNameFocus}
                          onBlur={handleNameBlur}
                          style={{
                            border: isnameFocused
                              ? "1px solid rgba(53, 63, 102, 1)"
                              : "1px solid #ced4da",
                            boxShadow: isnameFocused ? "none" : "none",
                          }}
                          name="email"
                          value={formValues.email}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <p className="blank_message">{formErrors.email}</p>
                      </div>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput2">
                      <div className="mb-3">
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          className="input_box"
                          onFocus={handlePswdFocus}
                          onBlur={handlePswdBlur}
                          style={{
                            border: ispswdFocused
                              ? "1px solid rgba(53, 63, 102, 1)"
                              : "1px solid #ced4da",
                            boxShadow: ispswdFocused ? "none" : "none",
                          }}
                          name="password"
                          value={formValues.password}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <p className="blank_message">{formErrors.password}</p>
                      </div>
                    </Form.Group>
                  </Form>
                  <p className="forgot-password">
                    <span onClick={handleForgotPassword}>Forgot password?</span>
                  </p>
                  <button type="submit" className="login_button">
                    Login
                  </button>

                  <div></div>
                </form>
                <p className="or_para">Or</p>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className="form-group btn btn-primary fb-btn"
                        onClick={signInWithFacebook}
                      >
                        <FontAwesomeIcon
                          icon={faFacebookSquare}
                          className="svg_icon"
                        />
                        LOGIN WITH FACEBOOK
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div
                        className="form-group btn btn-primary gplus-btn"
                        onClick={signInWithGoogle}
                      >
                        <FontAwesomeIcon icon={faGoogle} className="svg_icon" />
                        LOGIN WITH GOOGLE
                      </div>
                    </div>
                  </div>
                </div>

                <p className="register_now">
                  Don't have an account?{" "}
                  <span
                    type="button"
                    className="redirect-to-register-button link"
                    onClick={openRegister}
                  >
                    Register
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
