import React, { useEffect } from "react";
import { Footer } from "./Footer";
import axios from "axios";
import { bwcDealsApi } from "./Api";
import { useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./ConfirmEmail.css";
import Cookies from "js-cookie";
import { MetaTitleAndDescription } from "./MetaTitleAndDescription";
export const ConfirmEmail = () => {
  document.title="- BwcDeals"
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const confirmPart = currentUrl.split("/confirm/")[1];
  const csrftoken =  Cookies.get('csrftoken');

  useEffect(() => {
    const collectedData = { confirmation_code: confirmPart };
    axios
      .post(`${bwcDealsApi}/verify`, collectedData, {
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken
        },
      })
      .then((res) => {
        setError(null);
        setLoading(false);
        if (res.data.status === "true") {
          setSuccess(res.data.response);
          // setTimeout(() => {
          //   setSuccess(null);
          // }, 1000);
        } else {
          setError(res.data.message);
          // setTimeout(() => {
          //   setError(null);
          // }, 1000);
        }
      })
      .catch((error) => {
        setLoading(false); 
      });
  }, []);

  return (
    <>
    <MetaTitleAndDescription />

      <div className="forgetpswd_main ">
        <div className="container">
          <div className="register_form">
            <h1 className="forgotpswd_heading">Email Status </h1>

            <div className="forgotpswd_div_confirm">
              {loading ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                <>
                  {success && (
                    <Alert variant="success" style={{ fontSize: "20px" }}>
                      {success}
                    </Alert>
                  )}
                  {error && (
                    <Alert variant="danger" style={{ fontSize: "20px" }}>
                      {error}
                    </Alert>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
