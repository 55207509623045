import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import "./FeaturedStoreCarousel.css";
import axios from "axios";
import { bwcDealsApi } from "./Api";

import { useLocation } from "react-router-dom";

export const FeaturedStoreCarousel = () => {
  const [data, setData] = useState([]);
  const location = useLocation();
  useEffect(() => {
    axios
      .get(`${bwcDealsApi}/featured_stores`)
      .then((res) => {
        setData(res.data.response.featured_data_arr);
      })
      .catch((error) => {
      });
  }, []);
  const handleClick = (slug) => {
    window.open(`/store/${slug}`, "_self");
  };

  return (
    <div className="carousel_card1 text-center">
      <div className="carousel-container">
        <h3>Featured Stores</h3>
        <Carousel controls={false} indicators={false} interval={1000}>
          {data.map((item, index) => (
            <Carousel.Item key={index} onClick={() => handleClick(item.slug)}>
              <img
                className="feature_img"
                // d-block w-100
                src={item.image_url}
                alt={`Featured Store ${index + 1}`}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
