
import React, { useEffect, useState } from 'react'
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { CheckoutForm } from './CheckoutForm';
import axios from 'axios';
import { bwcDealsApi } from './Api';
import Cookies from 'js-cookie';
import "./CheckoutForm.css"
import { MetaTitleAndDescription } from './MetaTitleAndDescription';


export const Payment = () => {
  document.title="- BwcDeals"
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState(null);
  const token= JSON.parse(Cookies.get("loginTokenBwcDeals")===undefined?null:Cookies.get("loginTokenBwcDeals"));
  const csrftoken =  Cookies.get('csrftoken');



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_ID);

const options = {
  // clientSecret: {{process.env.REACT_APP_STRIPE_CLIENT_SECRET}},
  mode: 'payment',
  amount: 1099,
  currency: 'usd',
  appearance: {
 
    width:"500px"
  },
};





  useEffect(()=>{

    axios
    .post(
      `${bwcDealsApi}/subscriptions_setup_intent`,
      {user_id:token && token.response.user_id,
    }, 
    {
      headers: {
        "Content-Type": "application/json",
        'X-CSRFToken': csrftoken
      },
    }
    )
 
    .then((res) => {
     setPaymentIntentClientSecret(res.data.client_secret);
    
    })
    .catch((error) => {
      
    });
  },[])


  return (

    <>
    <MetaTitleAndDescription />
    <div>
<Elements stripe={stripePromise} options={options}>
    <CheckoutForm paymentIntentClientSecret={paymentIntentClientSecret}/>
  </Elements>

    </div>
    </>
  )
}
