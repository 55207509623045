import React, { useState } from "react";
// import { NavbarMain } from "../Components/Navbar";
import { FeaturedStoreCarousel } from "../Components/FeaturedStoreCarousel";
import { Footer } from "../Components/Footer";
import "./EditProfilePage.css";
import { Alert, Breadcrumb } from "react-bootstrap";
import axios from "axios";
import user from "../Pictures/lady.png";
import { bwcDealsApi } from "./Api";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MetaTitleAndDescription } from "./MetaTitleAndDescription";

export const EditProfilePage = () => {
  document.title="- BwcDeals"
  const [editprofile, setEditProfile] = useState([]);
  const [profile, setProfile] = useState("");
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const token = JSON.parse(
    Cookies.get("loginTokenBwcDeals") === undefined
      ? null
      : Cookies.get("loginTokenBwcDeals")
  );
  const csrftoken =  Cookies.get('csrftoken');
  const initialValues = {
    user_id: token.response.user_id,
    image: "",
    dob: "",
    gender: "male",
    name: token.response.name,
    brief: "",
    email: token.response.email,
    mobile: "",
    website: "",
    address: "",
  };

  const PswdInitialValue = {
    old_password: "",
    new_password: "",
    new_password_confirmation: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [PswdValues, setPswdValues] = useState(PswdInitialValue);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [errorPassword, setErrorPassword] = useState(null);
  const [success, setSuccess] = useState(null);
  const [successPassword, setSuccessPassword] = useState(null);
  const [successErrorPassword, setSuccessErrorPassword] = useState(null);
  const [error, setError] = useState("");

  const getData = () => {
    let user_id = token && token.response.user_id;
    axios
      .post(
        `${bwcDealsApi}/user`,
        {
          user_id:user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': csrftoken
          },
        }
      )
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((error) => {
      });
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let user_id = token && token.response.user_id;
    axios
      .post(
        `${bwcDealsApi}/user_dashboard`,
        {
          user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': csrftoken
          },
        }
      )
      .then((res) => {
        // console.log(res)
        setProfile(res.data.response.user_data);
      })
      .catch((error) => {
      });
  }, []);

  useEffect(() => {
    if (userData) {
      setFormValues({
        user_id: token.response.user_id,
        image: "",
        dob: userData.user_dob,
        gender: userData.user_gender,
        name: userData.user_name,
        brief: userData.user_biography,
        email: userData.user_email,
        mobile: userData.user_mobile,
        website: userData.user_website,
        address: userData.user_address,
      });
    }
  }, [userData]);

  const handleChangeImage = (event) => {
    const file = event.target.files[0];
    // console.log("Image Upload",file)
    setFormValues({ ...formValues, [event.target.name]: file });
  };
  // console.log("Image Upload",formValues)
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });
  };

  const handlePswdChange = (event) => {
    const { name, value } = event.target;

    setPswdValues({ ...PswdValues, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Handle Submit",formValues)
    if (token) {
      try {
        const response = await axios.post(
          `${bwcDealsApi}/profile_update`,
          formValues,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              'X-CSRFToken': csrftoken
            },
          }
        );
        if (response.status === 200) {
          // console.log("Res",response)
          setSuccess(response.data.message);
          setError(null);
          Cookies.set(
            "loginTokenBwcDeals",
            JSON.stringify({
              ...token,
              response: { ...token.response, name: formValues.name },
            })
          );
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          setTimeout(() => {
            setSuccess(null);
            navigate("/user/profile");
          }, 3000);
        }
      } catch (err) {
        setError(err.response.data.message);
        setSuccess(null);
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };

  const handlePassword = async (event) => {
    event.preventDefault();

    if (PswdValues.new_password !== PswdValues.new_password_confirmation) {
      setErrorPassword("New and Confirm Password should be same. ");
    } else {
      setErrorPassword(null);
      let dataPassword = { ...PswdValues, user_id: token.response.user_id };
      axios
        .post(`${bwcDealsApi}/change_password`, dataPassword, {
          headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': csrftoken
          },
        })

        .then((res) => {
          if (res.data.status === "true") {
            setSuccessErrorPassword(null);
            setSuccessPassword(res.data.message);

            setTimeout(() => {
              setSuccessErrorPassword(null);
              setSuccessPassword(null);
              setShowPasswordForm(false);
              setPswdValues(PswdInitialValue);
            }, 5000);
          } else {
            setSuccessPassword(null);
            setSuccessErrorPassword(res.data.message);
            setTimeout(() => {
              setSuccessErrorPassword(null);
              setSuccessPassword(null);
              setShowPasswordForm(true);
              setPswdValues(PswdInitialValue);
            }, 5000);
          }
        })
        .catch((error) => {});
    }
  };

  const togglePasswordForm = () => {
    setShowPasswordForm(!showPasswordForm);
  };

  return (
    <>
    <MetaTitleAndDescription />
    <div>
      <div className="container">
        <h2 style={{ marginTop: "20px" }}>Edit Profile</h2>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Edit Profile</Breadcrumb.Item>
        </Breadcrumb>

        <div className="coupon-page-block editprofile-page profile-page">
          <div className="row">
            <div className="col-lg-9 col-md-8">
              <div className="edit-profile-main-block">
                {success === "Your profile has been updated" && (
                  <Alert variant="success" style={{ fontSize: "20px" }}>
                    {success}
                  </Alert>
                )}
                {success === "All fields are required." && (
                  <Alert variant="danger" style={{ fontSize: "20px" }}>
                    {success}
                  </Alert>
                )}

                <form
                  className="edit-profile-form profile-form"
                  onSubmit={handleSubmit}
                  enctype="multipart/form-data"
                >
                  <div class="row">
                    <div class="col-md-5 ac-profile-img">
                      <img
                        src={profile.user_image}
                        alt="User"
                        className="user_image_profile"
                      />
                    </div>

                    <div class="col-md-7 form-group input-file-block">
                      <label for="image">User Image</label> -{" "}
                      <p class="inline info"></p>
                      <input
                        class="input-file"
                        id="image"
                        name="image"
                        type="file"
                        accept="image/*"
                        onChange={handleChangeImage}
                      />
                      <p class="info">Choose custom image</p>
                      <small class="text-danger"></small>
                    </div>
                  </div>

                  <div className="form-group">
                    <label for="name">
                      Enter Your Full Name<span className="star_color">*</span>
                    </label>

                    <input
                      type="text"
                      name="name"
                      id="user_name"
                      className="form_control"
                      placeholder="Enter Your Full Name"
                      required
                      value={formValues.name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label for="dob">Date of Birth</label>

                    <input
                      type="date"
                      name="dob"
                      id="dob"
                      className="form_control"
                      placeholder=""
                      value={formValues.dob}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label for="email">
                      Email Address<span className="star_color">*</span>
                    </label>

                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form_control"
                      placeholder="Enter Your Email Address"
                      required
                      disabled
                      value={formValues.email}
                      onChange={handleChange}
                      style={{
                        backgroundColor: "rgba(0,0,0,0.1)",
                        cursor: "not-allowed",
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label for="mobile">
                      Enter Your Mobile Number
                      <span className="star_color">*</span>
                    </label>

                    <input
                      type="number"
                      name="mobile"
                      id="mobile"
                      className="form_control"
                      placeholder="Enter Mobile Number"
                      required
                      value={formValues.mobile}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label for="gender">Choose Gender</label>

                    <select
                      className="form_control"
                      name="gender"
                      id="gender"
                      value={formValues.gender}
                      onChange={handleChange}
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label for="address">Enter Your Address</label>
                    <textarea
                      className="form_control text_area"
                      id="address"
                      name="address"
                      rows="5"
                      value={formValues.address}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <div class="form-group">
                    <label for="website">Website</label>

                    <input
                      type="url"
                      id="website"
                      name="website"
                      className="form_control"
                      placeholder="Enter Your Website"
                      value={formValues.website}
                      onChange={handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label for="message">Biography</label>

                    <textarea
                      className="form_control text_area"
                      id="brief"
                      name="brief"
                      rows="5"
                      value={formValues.brief}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <div className="submit-profile-btn">
                      <button type="submit" className="btn_primary">
                        Submit
                      </button>
                    </div>
                  </div>

                  <div>
                    <p className="pswd_change" onClick={togglePasswordForm}>
                      Want to change your password?
                    </p>
                  </div>
                </form>

                {showPasswordForm && (
                  <form
                    className="edit-profile-form profile-form"
                    onSubmit={handlePassword}
                  >
                    {successPassword && (
                      <Alert variant="success" style={{ fontSize: "20px" }}>
                        {successPassword}
                      </Alert>
                    )}
                    {successErrorPassword && (
                      <Alert variant="danger" style={{ fontSize: "20px" }}>
                        {successErrorPassword}
                      </Alert>
                    )}
                    <div className="form-group">
                      <label for="oldpswd">
                        Enter Old Password<span className="star_color">*</span>
                      </label>

                      <input
                        type="password"
                        name="old_password"
                        id="oldpswd"
                        className="form_control"
                        placeholder="Enter Your Old Password"
                        required
                        value={PswdValues.old_password}
                        onChange={handlePswdChange}
                      />
                    </div>

                    <div className="form-group">
                      <label for="newpswd">
                        Enter New Password<span className="star_color">*</span>
                      </label>

                      <input
                        type="password"
                        name="new_password"
                        id="newpswd"
                        className="form_control"
                        placeholder="Enter Your New Password"
                        required
                        value={PswdValues.new_password}
                        onChange={handlePswdChange}
                      />
                    </div>

                    <div className="form-group">
                      <label for="confirmpswd">
                        Confirm New Password
                        <span className="star_color">*</span>
                      </label>

                      <input
                        type="password"
                        name="new_password_confirmation"
                        id="confirmpswd"
                        className="form_control"
                        placeholder="Confirm New Password"
                        required
                        value={PswdValues.new_password_confirmation}
                        onChange={handlePswdChange}
                      />
                    </div>
                    {errorPassword && (
                      <p className="error_password_notification">
                        {errorPassword}
                      </p>
                    )}
                    <div className="form-group">
                      <div className="submit-profile-btn">
                        <button type="submit" className="btn_primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                )}

                <div className="featured_carousel_small_screen">
                  <FeaturedStoreCarousel />
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-4">
              <div class="coupon-sidebar">
                <div class="page-sidebar-widget popular-store-widget">
                  <div className="featured_carousel_long_div">
                    <FeaturedStoreCarousel />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>

    </>
  );
};
