import React from 'react'
import {Footer} from './Footer'
import "./VerifyAccount.css"
import { MetaTitleAndDescription } from './MetaTitleAndDescription'
export const VerifyAccount = () => {
  document.title="- BwcDeals"
  return (
    <>
     <MetaTitleAndDescription />

      <div className="forgetpswd_main ">
        <div className="container">
          <div className="register_form">
            <h1 className="forgotpswd_heading">Check Your Email</h1>

            <div className="forgotpswd_div">
                <h5>You must validate your account before you can sign in.</h5>
                <p>Validate your account by clicking the link in the email sent to you. If you don't see our email, please be sure to check your junk/spam folder.</p>
                <h4>Thanks,</h4>
                <h4>BwcDeals</h4>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}