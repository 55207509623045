import React, { useEffect, useState } from "react";
import { Footer } from "./Footer";
import { Breadcrumb } from "react-bootstrap";
import "./Forum.css";
import { FeaturedStoreCarousel } from "./FeaturedStoreCarousel";
import { bwcDealsApi } from "./Api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MetaTitleAndDescription } from "./MetaTitleAndDescription";

export const Forum = () => {
  const [loading, setLoading] = useState(true);
  document.title = "- BwcDeals";
  const [forum, setForum] = useState([]);
  const navigate = useNavigate();
  const token = JSON.parse(
    Cookies.get("loginTokenBwcDeals") === undefined
      ? null
      : Cookies.get("loginTokenBwcDeals")
  );
  useEffect(() => {
    axios
      .get(`${bwcDealsApi}/forums`)
      .then((res) => {
        setLoading(false);
        setForum(res.data.froum_list);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const handlenewDealsPost = () => {
    if (token === "null") {
      navigate("/login");
    } else {
      navigate("/submitdeal");
    }
  };

  const handleNavigate = (type) => {
    if (type == "g") {
      navigate("/educator");
    } else if (type == "d") {
      navigate("/newdeals");
    }
  };
  return (
    <>
    <MetaTitleAndDescription />
      <div className="container">
        <h2 style={{ marginTop: "20px" }}>Forum</h2>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Forums</Breadcrumb.Item>
        </Breadcrumb>

        <div className="forum_section">
        {loading ? (
              <div className="loading-spinner-forum">
                <FontAwesomeIcon icon={faSpinner} spin fontSize="60px" />
              </div>
            ) : <div className="forum_section_left_div">
             {
              forum.map((item) => {
                return (
                  <div className="forum_box text-center">
                    <h1 style={{ color: "rgba(53, 63, 102, 1)" }}>
                      {item.title}
                    </h1>
                    <hr />
                    <p className="education_post">{item.posts} POSTS</p>
                    <hr />
                    <p>{item.detail}..</p>
                    <hr />
                    <div className="forum_btn">
                      <button
                        className="forum_button"
                        onClick={() => handleNavigate(item.type)}
                      >
                        VISIT FORUM
                      </button>
                      <button
                        className="forum_button"
                        onClick={handlenewDealsPost}
                      >
                        ADD NEW POST
                      </button>
                    </div>
                  </div>
                );
              })
}
          </div>}

          <div>
            <FeaturedStoreCarousel />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
